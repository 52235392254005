import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth, auth } from "../../../context/AuthContext";
import Swal from "sweetalert2";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";

import Footer from "structures/Footer";
import LogoBox from "../LogoBox";
import {
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  useEffect(() => {
    // Initialize reCAPTCHA upon component mount
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      // eslint-disable-next-line no-unused-vars
      callback: function (response) {
        // reCAPTCHA solved - this callback will be called
      },
    });
  }, []);

  const handleLogin = async () => {
    try {
      let cred = await login(email, password);
      if (cred === "Verification_process") {
        Swal.fire({
          icon: "warning",
          title: "Aviso",
          text: "Se ha enviado un correo electrónico para verificar la cuenta, necesita verificar antes de iniciar sesión",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intenta de nuevo",
        });
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        // Handle the multifactor authentication
        const resolver = getMultiFactorResolver(auth, error);
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const appVerifier = window.recaptchaVerifier;
        // Send SMS verification code.
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, appVerifier)
          .then(async function (verificationId) {
            const verificationCode = await Swal.fire({
              icon: "info",
              title: "Verificación de dos pasos",
              text: `Se ha enviado un código de verificación a ${phoneInfoOptions.multiFactorHint.phoneNumber}. Introduce el código:`,
              input: "text",
              confirmButtonText: "Verificar",
            });
            try {
              const cred = PhoneAuthProvider.credential(verificationId, verificationCode.value);
              const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
              // eslint-disable-next-line no-unused-vars
              resolver.resolveSignIn(multiFactorAssertion).then(function () {
                window.location = "/home";
              });
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error, intenta de nuevo",
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Usuario o contraseña incorrectos",
        });
      }
    }
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };

  return (
    <>
      <CustomBox
        position="fixed"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundColor: "#039ECC",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <CustomBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="absolute"
        zIndex={2}
        top="-4rem"
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <LogoBox />
            <Card
              sx={{
                backgroundColor: "white",
                borderRadius: "2rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CustomBox pt={4} pb={3} px={3}>
                <CustomBox component="form" role="form">
                  <CustomBox mb={2}>
                    <CustomInput
                      type="email"
                      label="Correo"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CustomBox>
                  <CustomBox mb={2}>
                    <CustomInput
                      type="password"
                      label="Contraseña"
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyPress={handleKeypress}
                    />
                  </CustomBox>
                  <CustomBox mt={1} mb={1}>
                    <CustomBox mt={1} mb={1} textAlign="center">
                      <CustomTypography variant="button" color="primary">
                        <CustomTypography
                          component={Link}
                          to="/recuperar-contrasena"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                        >
                          ¿Olvidaste tu contraseña?
                        </CustomTypography>
                      </CustomTypography>
                    </CustomBox>
                    <div style={{ textAlign: "center" }}>
                      <CustomButton
                        component={Link}
                        onClick={handleLogin}
                        variant="gradient"
                        color="info"
                        width="60%"
                      >
                        Entrar
                      </CustomButton>
                    </div>
                  </CustomBox>
                </CustomBox>
              </CustomBox>
            </Card>
          </Grid>
        </Grid>
      </CustomBox>
      <CustomBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <Footer light />
      </CustomBox>
      <div id="recaptcha-container"></div>
    </>
  );
}

export default SignIn;
