import React from "react";
import { Steps } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const items = [
  {
    title: "Solicitando Emisión",
  },
  {
    title: "Enviada a CES",
  },
  {
    title: "Emitiendo a Compañía",
  },
  {
    title: "Revisando Emisión",
  },
  {
    title: "Registrado",
  },
];

const StepProgress = ({ externalStep }) => {
  return (
    <>
      <div
        style={{
          marginBottom: "4rem",
          width: "80%",
          textAlign: "center",
          margin: "16px auto",
        }}
      >
        <Steps
          current={externalStep}
          labelPlacement="vertical"
          size="default"
          responsive={true}
          progressDot={(dot, { status }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                marginTop: "-20px",
                marginLeft: "-20px",
                backgroundColor:
                  status === "finish" ? "#039ECC" : status === "process" ? "#039ECC" : "#81CFE6",
              }}
            >
              {status === "finish" && (
                <CheckOutlined
                  style={{
                    fontSize: "32px",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                />
              )}
            </div>
          )}
        >
          {items.map((item, index) => (
            <Steps.Step
              key={index}
              title={
                <div style={{ color: "#000", paddingTop: "16px", whiteSpace: "nowrap" }}>
                  {item.title}
                </div>
              }
              description={
                <div
                  style={{
                    paddingTop: "16px",
                    paddingBottom: "32px",
                    textAlign: "center",
                  }}
                ></div>
              }
            />
          ))}
        </Steps>
      </div>
    </>
  );
};

export default StepProgress;

StepProgress.propTypes = {
  externalStep: PropTypes.number,
};
