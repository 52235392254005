import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const DialogContext = createContext();
const DialogUpdateContext = createContext();

export function useDialog() {
  return useContext(DialogContext);
}

export function useDialogUpdate() {
  return useContext(DialogUpdateContext);
}

export function DialogProvider({ children }) {
  const [open, setOpen] = useState(true);

  function toggleDialog() {
    setOpen((prevState) => !prevState);
  }

  DialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <DialogContext.Provider value={open}>
      <DialogUpdateContext.Provider value={toggleDialog}>{children}</DialogUpdateContext.Provider>
    </DialogContext.Provider>
  );
}
