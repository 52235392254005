import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const StepContext = createContext();
const StepUpdateContext = createContext();

export function useStep() {
  return useContext(StepContext);
}

export function useStepUpdate() {
  return useContext(StepUpdateContext);
}

export function StepProvider({ children }) {
  const [step, setStep] = useState(false);

  function toggleStep(input) {
    if (input === 1) {
      setStep(false);
    } else if (input === 2) {
      setStep(true);
    }
  }

  StepProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <StepContext.Provider value={step}>
      <StepUpdateContext.Provider value={toggleStep}>{children}</StepUpdateContext.Provider>
    </StepContext.Provider>
  );
}
