import CustomBox from "components/CustomBox";

import inter_logo_white from "assets/images/inter_logo_white.png";

function LogoBox() {
  return (
    <>
      <CustomBox
        component="img"
        src={inter_logo_white}
        alt="Inter"
        sx={{ objectFit: "contain", width: "100%", height: "auto", padding: "1em 1em 0 1em" }}
        mb={2}
        mt={2}
        zIndex={2}
      />
    </>
  );
}

export default LogoBox;
