import React from "react";

import CustomNavbar from "../../structures/CustomNavbar";
import CustomBox from "components/CustomBox";
import Footer from "structures/Footer";
import Card from "@mui/material/Card";
import Requests from "./sections/Requests";

const RequestHistory = () => {
  return (
    <>
      <CustomNavbar tabValue={2} />

      <Card
        sx={{
          p: 1,
          mt: 20,
          mb: 4,
          height: "fill-available",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.9),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Requests />
      </Card>

      <CustomBox width="100%" position="relative" zIndex={2} bottom="1.625rem">
        <Footer />
      </CustomBox>
    </>
  );
};

export default RequestHistory;
