import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";
import React from "react";
import { useData } from "./DataContext";
import PropTypes from "prop-types";

const ItemCounter = ({ externalCount }) => {
  const data = useData();
  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <CustomBox display="flex" justifyContent="center" alignItems="center">
          <CustomTypography variant="h4" fontWeight="bold" fontSize="1.5rem" textAlign="center">
            No. de Items a enviar:
          </CustomTypography>
          <CustomBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "0.5rem",
              width: "3.5rem",
              height: "2.5rem",
              marginLeft: "1rem",
              marginRight: "1rem",
              backgroundColor: "grey.300",
            }}
          >
            <CustomTypography
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
              textAlign="center"
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {data.length ? data.length : externalCount}
            </CustomTypography>
          </CustomBox>
        </CustomBox>
      </div>
    </>
  );
};

export default ItemCounter;

ItemCounter.propTypes = {
  externalCount: PropTypes.number,
};
