export default function DataProcess(xlsxData) {
  const counts = xlsxData.reduce(
    (acc, cur) => {
      const movimiento = cur.MOVIMIENTO;
      switch (movimiento) {
        case "A":
        case "B":
        case "C":
          acc.all[movimiento] = (acc.all[movimiento] || 0) + 1;
          acc.altas[movimiento] = (acc.altas[movimiento] || 0) + 1;
          break;
        case "D":
          acc.all[movimiento] = (acc.all[movimiento] || 0) + 1;
          acc.bajas[movimiento] = (acc.bajas[movimiento] || 0) + 1;
          break;
        default:
          break;
      }
      return acc;
    },
    { all: {}, altas: {}, bajas: {} }
  );

  const groupedData1 = xlsxData.reduce((acc, cur) => {
    if (cur.MOVIMIENTO === "A" || cur.MOVIMIENTO === "B" || cur.MOVIMIENTO === "C") {
      acc.push({
        tipoSolicitudId: cur.MOVIMIENTO,
        motivoEndosoId: 1,
        polizaId: cur["NUMEROPOLIZA"] ?? 0,
        //numCertificado: cur.CERTIFICADO ?? "",
        fechaMovimiento: cur["FECHADEMOVIMIENTO"] ?? "",
        asegurados: [
          {
            nombre: cur["NOMBRE"] ?? "",
            apellidoPaterno: cur["APELLIDOMATERNO"] ?? "",
            apellidoMaterno: cur["APELLIDOPATERNO"] ?? "",
            relacionId: cur["PARENTESCO"] ?? 0,
            sexoId: cur["GENERO"] ?? 0,
            fechaNacimiento: cur["FECHADENACIMIENTO"] ?? "",
            numCertificado: cur["CERTIFICADO"] ?? "",
          },
        ],
      });
    }
    return acc;
  }, []);

  const groupedData2 = xlsxData.reduce((acc, cur) => {
    if (cur.MOVIMIENTO === "D") {
      const key = `${cur.MOVIMIENTO}-${cur.CERTIFICADO}-${cur["NUMEROPOLIZA"]}`;
      if (!acc[key]) {
        acc[key] = {
          tipoSolicitudId: cur.MOVIMIENTO,
          motivoEndosoId: 2,
          polizaId: cur["NUMEROPOLIZA"] ?? 0,
          //numCertificado: cur.CERTIFICADO ?? "",
          fechaMovimiento: cur["FECHADEMOVIMIENTO"] ?? "",
          asegurados: [
            {
              nombre: cur["NOMBRE"] ?? "",
              apellidoPaterno: cur["APELLIDOMATERNO"] ?? "",
              apellidoMaterno: cur["APELLIDOPATERNO"] ?? "",
              relacionId: cur["PARENTESCO"] ?? 0,
              sexoId: cur["GENERO"] ?? 0,
              fechaNacimiento: cur["FECHADENACIMIENTO"] ?? "",
              numCertificado: cur["CERTIFICADO"] ?? "",
            },
          ],
        };
      } else {
        acc[key].asegurados.push({
          nombre: cur["NOMBRE"] ?? "",
          apellidoPaterno: cur["APELLIDOMATERNO"] ?? "",
          apellidoMaterno: cur["APELLIDOPATERNO"] ?? "",
          relacionId: cur["PARENTESCO"] ?? 0,
          sexoId: cur["GENERO"] ?? 0,
          fechaNacimiento: cur["FECHADENACIMIENTO"] ?? "",
          numCertificado: cur["CERTIFICADO"] ?? "",
        });
      }
    }
    return acc;
  }, {});

  const groupedData = [...groupedData1, ...Object.values(groupedData2)];

  return groupedData;
}
