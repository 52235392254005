import React, { useContext, useState, useEffect } from "react";
import { auth } from "./AuthContext";
import axios from "axios";
import PropTypes from "prop-types";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [clientID, setClientID] = useState();

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

  const email = auth.currentUser?.email;

  useEffect(() => {
    const fetchUserValues = async () => {
      const userToken = await auth.currentUser?.getIdToken(true);
      const result = await axios
        .get(url + "/users/email/" + email, {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setClientID(response.data.cliente.id);
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchUserValues();
  }, []);

  const value = {
    user,
    clientID,
  };

  UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
