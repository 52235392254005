import React, { useState, useEffect } from "react";
import axios from "axios";
import { read, utils } from "xlsx";
import { Alert, FormControl, MenuItem, Select, Snackbar } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import CircleIcon from "@mui/icons-material/Circle";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { IconButton, Paper, Popover } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useUser } from "../../../context/UserContext";

import { auth } from "context/AuthContext";
import CustomTypography from "../../../components/CustomTypography";
import CustomBox from "../../../components/CustomBox";
import MassiveDataFormat from "./MassiveDataFormat";
import { useDataUpdate } from "./DataContext";
import { useDialogUpdate } from "./DialogContext";
import DataProcess from "./DataProcess";
import Swal from "sweetalert2";
import { useError, useErrorUpdate } from "./ErrorContext";

const MassiveFormContent = () => {
  const updateData = useDataUpdate();
  const toggleDialog = useDialogUpdate();
  const errorList = useError();
  const updateErrorList = useErrorUpdate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [cliente, setCliente] = useState("");
  const [observaciones, setobservaciones] = useState("");

  const [RazonSocialError, setRazonSocialError] = useState(true);
  const [observacionesError, setobservacionesError] = useState(true);
  const [masivoFileError, setMasivoFileError] = useState(true);

  const [razonesSocialesValues, setRazonesSocialesValues] = useState([]);
  const [masivoFileURL, setMasivoFileURL] = useState("");
  const [masivoFileName, setMavisoFileName] = useState("");
  const [masivoData, setMasivoData] = useState([{}]);
  const [masivoFile, setMasivoFile] = useState();
  const [masivoDataProcessed, setMasivoDataProcessed] = useState([]);

  const [numPolizaArr, setNumPolizaArr] = useState([]);
  const [idPolizaArr, setIdPolizaArr] = useState([]);

  const user = useUser();
  const clientIdentification = user.clientID;

  const [isLoading, setIsLoading] = useState(true);

  const validateFormatComplete = (cliente, observaciones, masivoFileName) => (e) => {
    e.preventDefault();
    if (cliente === "") {
      setOpen(true);
      setRazonSocialError(true);
    } else if (observaciones === "") {
      setOpen(true);
      setobservacionesError(true);
    } else if (masivoFileName === "") {
      setOpen(true);
      setMasivoFileError(true);
    } else {
      handleSubmit();
    }
  };

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

  const handleClienteChange = (event) => {
    setRazonSocialError(false);
    setCliente(event.target.value);
  };

  const handleobservacionesChange = (event) => {
    setobservacionesError(false);
    setobservaciones(event.target.value);
  };

  function formatDate(fecha) {
    if (fecha === undefined) {
      return "";
    }
    const dateValue = fecha;
    const milliseconds = (dateValue - 25569) * 86400 * 1000; // Convert Excel date value to Unix timestamp in milliseconds
    const date = new Date(milliseconds);
    const dateString = date.toISOString().slice(0, 10);
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const handleMasivoFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;

    // Check if the file is an .xlsx file
    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setMasivoFileError(true);
      setMavisoFileName("");
      Swal.fire({
        title: "Error",
        text: "Por favor, suba el archivo de cargas masivas con extensión .xlsx.",
        toast: true,
        position: "top-end",
        timer: 6000,
        icon: "error",
        color: "#fff",
        background: "#EA5151",
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          container: "my-swal",
        },
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      return;
    } else {
      setMasivoFileError(false);
    }

    setMavisoFileName(fileName);
    setMasivoFile(file);
    setMasivoFileURL(URL.createObjectURL(file));

    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = utils.sheet_to_json(ws, { header: 1 });

      /* Remove Empty Lines */
      for (let i = 0; i < data.length; i++) {
        if (data[i].length === 0) {
          data.splice(i, 1);
          i--;
        }
      }

      /* Change all text from the file to uppercase */
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (typeof data[i][j] === "string") {
            data[i][j] = data[i][j].toUpperCase();
          }
        }
      }

      /* REMOVE THE SPACE IN THE HEADERS AND JOIN WORDS IF APPLICABLE (CASE: APELLIDOS & FECHA DE NACIMIENTO) */
      for (let i = 0; i < data[0].length; i++) {
        if (typeof data[0][i] === "string") {
          data[0][i] = data[0][i].replace(/\s/g, "");
          if (data[0][i] === "APELLIDO" || data[0][i] === "FECHA") {
            data[0][i] = data[0][i] + data[0][i + 1];
            data[0].splice(i + 1, 1);
          }
        }
      }

      //use the data to create a new array of objects using the headers as keys: [{header1: value1, header2: value2}]
      const headers = data.shift();
      const formattedData = data.map((array) => {
        let obj = {};
        headers.forEach((header, i) => {
          obj[header] = array[i];
        });
        return obj;
      });

      // Define the expected headers
      const expectedHeaders = [
        "MOVIMIENTO",
        "APELLIDOPATERNO",
        "APELLIDOMATERNO",
        "NOMBRE",
        "CERTIFICADO",
        "PARENTESCO",
        "SUBGRUPO",
        "FECHADEMOVIMIENTO",
        "NUMEROPOLIZA",
        "FECHADENACIMIENTO",
        "GENERO",
        "FECHADEANTIGÜEDAD",
      ];

      // Check if the headers match the expected headers
      const headersMatch = expectedHeaders.every((header, index) => {
        return headers[index] === header;
      });

      // If the headers do not match, set the file error state variable and display a Swal message
      if (!headersMatch) {
        setMasivoFileError(true);
        setMavisoFileName("");
        Swal.fire({
          title: "Error",
          text: "El archivo no tiene el formato esperado, intentar nuevamente.",
          toast: true,
          position: "top-end",
          timer: 6000,
          color: "#fff",
          background: "#EA5151",
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            container: "my-swal",
          },
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      } else {
        setMasivoFileError(false);
      }

      const errorRows = [];
      const testData = [...formattedData];
      //go through each object and validate that in the values. If not found, delete that object from the array of objects
      formattedData.forEach((row, index) => {
        const parentesco = row.PARENTESCO;
        const genero = row.GENERO;
        const fechaNacimiento = row.FECHADENACIMIENTO;
        const nacimientoDate = formatDate(fechaNacimiento);
        const edad = new Date().getFullYear() - new Date(nacimientoDate).getFullYear();
        const tipoOrden = row.MOVIMIENTO;
        const appellidoPaterno = row.APELLIDOPATERNO;
        const appellidoMaterno = row.APELLIDOMATERNO;
        const nombre = row.NOMBRE;
        const certificado = row.CERTIFICADO;
        const fechaMovimiento = row.FECHADEMOVIMIENTO;
        const movimientoDate = formatDate(fechaMovimiento);
        const fechaAntiguedad = row.FECHADEANTIGÜEDAD;
        const antiguedadDate = formatDate(fechaAntiguedad);
        const numPoliza = row.NUMEROPOLIZA;

        if (
          parentesco !== "TITULAR" &&
          parentesco !== "CONYUGE" &&
          parentesco !== "CÓNYUGE" &&
          parentesco !== "HIJA" &&
          parentesco !== "HIJO"
        ) {
          errorRows.push(
            `Error en la fila: ${index + 2}: ${parentesco || ""} parentesco inválido.`
          );
          testData.splice(testData.indexOf(row), 1);
        } else if (genero !== "FEMENINO" && genero !== "MASCULINO") {
          errorRows.push(`Error en la fila: ${index + 2}: ${genero || ""} género invalido.`);
          testData.splice(testData.indexOf(row), 1);
        } else if ((parentesco === "CONYUGE" || parentesco === "CÓNYUGE") && edad < 18) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${
              nacimientoDate || ""
            } cónyuge no puede ser menor de edad.`
          );
        } else if (parentesco === "TITULAR" && edad < 18) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${
              nacimientoDate || ""
            } titular no puede menor de edad.`
          );
        } else if (edad > 100) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${nacimientoDate || ""} no puede ser mayor a 100 años.`
          );
        } else if (tipoOrden === undefined || tipoOrden === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(`Error en la fila: ${index + 2}: ${tipoOrden || ""} tipo de orden vacío.`);
        } else if (
          tipoOrden !== "A" &&
          tipoOrden !== "B" &&
          tipoOrden !== "C" &&
          tipoOrden !== "D"
        ) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${tipoOrden || ""} tipo de orden inválido.`
          );
        } else if (appellidoPaterno === undefined || appellidoPaterno === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${appellidoPaterno || ""} apellido paterno vacío.`
          );
        } else if (appellidoMaterno === undefined || appellidoMaterno === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${appellidoMaterno || ""} apellido materno vacío.`
          );
        } else if (nombre === undefined || nombre === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(`Error en la fila: ${index + 2}: ${nombre || ""} nombre vacío.`);
        } else if (certificado === undefined || certificado === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(`Error en la fila: ${index + 2}: ${certificado || ""} certificado vacío.`);
        } else if (movimientoDate === undefined || movimientoDate === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${movimientoDate || ""} fecha de movimiento vacío.`
          );
        } else if ((antiguedadDate === undefined || antiguedadDate === "") && tipoOrden === "A") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${antiguedadDate || ""} fecha de antiguedad vacío.`
          );
        } else if (numPoliza === undefined || numPoliza === "") {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${numPoliza || ""} número de póliza vacío.`
          );
        }
        //check if the numPoliza is in the array of numPolizaArr (if it is not, it is an invalid poliza)
        else if (numPoliza) {
          const numPolizaIndex = numPolizaArr.indexOf(numPoliza);
          if (numPolizaIndex === -1) {
            testData.splice(testData.indexOf(row), 1);
            errorRows.push(
              `Error en la fila: ${index + 2}: ${numPoliza || ""} número de póliza inválido.`
            );
          }
          // if the numPoliza is in the array, then get the idPoliza from the idPolizaArr and replace it to the row
          else {
            const numPolizaId = idPolizaArr[numPolizaIndex];
            row.NUMEROPOLIZA = numPolizaId;
            testData[index] = row;
          }
        }

        // only accept dates in the format YYYY-MM-DD
        else if (!/^\d{4}-\d{2}-\d{2}$/.test(movimientoDate)) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${
              movimientoDate || ""
            } formato de fecha de movimiento inválido.`
          );
        } else if (!/^\d{4}-\d{2}-\d{2}$/.test(antiguedadDate)) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${
              antiguedadDate || ""
            } formato de fecha de antiguedad inválido.`
          );
        } else if (!/^\d{4}-\d{2}-\d{2}$/.test(nacimientoDate)) {
          testData.splice(testData.indexOf(row), 1);
          errorRows.push(
            `Error en la fila: ${index + 2}: ${
              nacimientoDate || ""
            } formato de fecha de nacimiento inválido.`
          );
        }
      });

      //Update error context
      updateErrorList(errorRows);

      /* Update state */
      setMasivoData(testData);
      setMasivoFileError(false);
      const processedData = DataProcess(testData);
      setMasivoDataProcessed(processedData);
    };
    reader.onerror = (evt) => {
      console.log("error reading file");
    };
  };

  useEffect(() => {
    const fetchRazonesSocialesValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/clientes/" + clientIdentification + "/razones-sociales", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setRazonesSocialesValues(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchRazonesSocialesValues();
  }, [clientIdentification]);

  useEffect(() => {
    const fetchPolizaValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/razones-sociales/" + cliente + "/polizas", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          //log the numeroPoliza and the idPoliza for each poliza
          response.data.forEach((poliza) => {
            //map the numeroPoliza and the idPoliza to the numPoliza and idPoliza arrays
            numPolizaArr.push(poliza.numeroPoliza);
            idPolizaArr.push(poliza.id);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchPolizaValues();
  }, [cliente]);

  const rawData = {
    cliente,
    observaciones,
    masivoFileName,
  };

  const handleSubmit = () => {
    /* Update state with each row of the xlsx file */
    masivoData.forEach((row) => {
      const parsedData = MassiveDataFormat(rawData, row);
      updateData(parsedData);
    });

    toggleDialog();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openInfo = Boolean(anchorEl);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Por favor llene los campos requeridos:
          {cliente === "" ? " Cliente," : ""}
          {observaciones === "" ? " observaciones," : ""}
          {masivoFileName === "" ? " Archivo para masivos" : ""}
        </Alert>
      </Snackbar>

      {isLoading === true ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            marginLeft: "-20px",
            marginTop: "-20px",
          }}
        />
      ) : (
        <>
          {/* CLIENTE */}
          <CustomBox
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={1}
            alignItems="center"
          >
            <CustomBox
              gridColumn="span 3"
              sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
            >
              <CustomTypography fontSize="0.9rem" sx={{ fontWeight: "bold", color: "#000" }}>
                Razón Social
              </CustomTypography>
            </CustomBox>

            {RazonSocialError === true ? (
              <>
                <CustomBox gridColumn="span 1" sx={{ alignItems: "center" }}>
                  <CircleIcon
                    sx={{
                      scale: ".5",
                      fontSize: "1.5rem",
                      color: "#EA5151",
                      float: "right",
                      marginTop: "0.125rem",
                    }}
                  />
                </CustomBox>
              </>
            ) : (
              <>
                <CustomBox gridColumn="span 1"></CustomBox>
              </>
            )}

            <CustomBox
              gridColumn="span 8"
              sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
            >
              <FormControl fullWidth required="true">
                <Select
                  labelId="razon-social"
                  id="client"
                  value={cliente}
                  onChange={handleClienteChange}
                  sx={{
                    height: "4vh",
                    backgroundColor: "rgba(217, 217, 217, 0.34)",
                    border: "none",
                    width: "80%",
                    paddingLeft: "16px",
                    color: "#000",
                  }}
                  IconComponent={() => (
                    <>
                      <FolderSharedIcon
                        color="primary"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: "8px",
                          fontSize: "3rem",
                        }}
                      />
                      <KeyboardArrowDownOutlinedIcon
                        color="black"
                        sx={{ fontSize: "3rem", mr: 1 }}
                      />
                    </>
                  )}
                >
                  {razonesSocialesValues.map((cliente) => (
                    <MenuItem key={cliente.id} value={cliente.id} sx={{ fontWeight: "bold" }}>
                      {cliente.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomBox>

            {/* NO POLIZA NOT REQUIRED SINCE IT IS PROVIDED IN THE XLSX FILE*/}

            {/* observaciones */}
            <CustomBox
              gridColumn="span 3"
              sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
            >
              <CustomTypography fontSize="0.9rem" sx={{ fontWeight: "bold", color: "#000" }}>
                Observaciones de entrega
              </CustomTypography>
            </CustomBox>

            {observacionesError === true ? (
              <>
                <CustomBox
                  gridColumn="span 1"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                >
                  <CircleIcon
                    sx={{
                      scale: ".5",
                      fontSize: "1.5rem",
                      color: "#EA5151",
                      float: "right",
                      marginTop: "0.125rem",
                    }}
                  />
                </CustomBox>
              </>
            ) : (
              <>
                <CustomBox gridColumn="span 1"></CustomBox>
              </>
            )}

            <CustomBox
              gridColumn="span 8"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginTop: "1rem",
              }}
            >
              <CustomTypography>
                <textarea
                  placeholder=""
                  type="textarea"
                  rows="2"
                  cols={window.innerWidth < 800 ? "auto" : "60"}
                  onChange={handleobservacionesChange}
                  value={observaciones}
                  style={{
                    fontFamily: "Montserrat",
                    backgroundColor: "rgba(217, 217, 217, 0.34)",
                    border: "none",
                    borderRadius: "1vh",
                    resize: "none",
                    height: "6vh",
                    width: "60%",
                    padding: "0.5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    outline: "none",
                  }}
                  required="true"
                />
              </CustomTypography>
            </CustomBox>

            {/* ARCHIVO PARA MASIVOS */}
            <CustomBox
              gridColumn="span 3"
              sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
            >
              <CustomTypography fontSize="0.9rem" sx={{ fontWeight: "bold", color: "#000" }}>
                Adjunto para masivos
              </CustomTypography>
            </CustomBox>

            {masivoFileError === true ? (
              <>
                <CustomBox
                  gridColumn="span 1"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                >
                  <CircleIcon
                    sx={{
                      scale: ".5",
                      fontSize: "1.5rem",
                      color: "#EA5151",
                      float: "right",
                      marginTop: "0.125rem",
                    }}
                  />
                </CustomBox>
              </>
            ) : (
              <>
                <CustomBox gridColumn="span 1"></CustomBox>
              </>
            )}
            <CustomBox gridColumn="span 4">
              <CustomButton
                //disable button if no razon social is selected
                disabled={cliente ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                size="small"
                sx={{
                  padding: "0.2rem",
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomTypography
                  variant="button"
                  sx={{
                    fontWeight: "regular",
                    color: "#fff",
                    fontSize: "0.8rem",
                    alignItems: "center",
                  }}
                >
                  Seleccionar Documento
                </CustomTypography>
                <input
                  type="file"
                  onChange={(e) => {
                    handleMasivoFileUpload(e);
                  }}
                  accept="application/csv"
                  hidden
                  required="true"
                />
              </CustomButton>
            </CustomBox>
            <CustomBox gridColumn="span 4"></CustomBox>

            {/* DISPLAY DEL ARCHIVO SUBIDO */}
            <CustomBox gridColumn="span 4"></CustomBox>
            <CustomBox gridColumn="span 4">
              <CustomTypography
                fontSize="0.9rem"
                sx={{
                  fontWeight: "regular",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {cliente === ""
                  ? "Seleccione una razón social para subir archivo."
                  : masivoFileName
                  ? masivoFileName
                  : "No se ha subido ningún archivo."}
              </CustomTypography>
              {errorList.length > 0 && cliente !== "" ? (
                <CustomTypography
                  sx={{
                    p: 1,
                    fontSize: "0.8rem",
                    fontWeight: "regular",
                    color: "#EA5151",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {masivoFileName
                    ? `${errorList.length} errores encontrados en el archivo. Algunos campos no fueron
                  cargados`
                    : ""}
                </CustomTypography>
              ) : (
                <></>
              )}
            </CustomBox>
            <CustomBox gridColumn="span 4"> </CustomBox>

            <CustomBox gridColumn="span 4" mt="8vh">
              <IconButton
                aria-label="help"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon sx={{ fontSize: "1.5rem", color: "#FFC805" }} />
              </IconButton>
              {/* POP-UP DE AYUDA */}
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openInfo}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Paper sx={{ p: 1, bgcolor: "#FFC805" }} elevation={1}>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ¿Necesitas ayuda?
                  </CustomTypography>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhoneOutlinedIcon sx={{ fontSize: "1rem", mr: 1 }} />
                    800 800 1107
                    <EmailOutlinedIcon sx={{ fontSize: "1rem", mr: 1, ml: 2 }} />
                    avisosgmm@inter.mx
                  </CustomTypography>
                </Paper>
              </Popover>{" "}
            </CustomBox>
            <CustomBox gridColumn="span 4" mt="8vh">
              <CustomButton
                //disabled={masivoFileName ? false : true}
                variant="contained"
                color="primary"
                component="label"
                size="small"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  width: "80%",
                  borderRadius: "2vh",
                }}
                type="submit"
                onClick={validateFormatComplete(cliente, observaciones, masivoFileName)}
              >
                <CustomTypography
                  variant="button"
                  sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                >
                  Siguiente
                </CustomTypography>
              </CustomButton>
              <CustomBox gridColumn="span 4"> </CustomBox>
            </CustomBox>
          </CustomBox>
        </>
      )}
    </>
  );
};

export default MassiveFormContent;
