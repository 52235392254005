//Takes inputData and groups them by the following properties:
//tipoSolicitudId, motivoEndosoId, polizaId
//If the properties are the same, the asegurados property is concatenated (1 request, multiple asegurados)
//into an array of objects
// if the properties are different, a new object is created (seperate request)

export default function MassiveDataGrouping(inputData) {
  const groupedData = inputData.reduce((acc, curr) => {
    const { tipoSolicitudId, motivoEndosoId, polizaId, observaciones, ...rest } = curr;
    const existingItem = acc.find(
      (item) =>
        item.tipoSolicitudId === tipoSolicitudId &&
        item.motivoEndosoId === motivoEndosoId &&
        item.polizaId === polizaId
    );
    if (existingItem) {
      const existingAsegurado = existingItem.asegurados.find(
        (asegurado) =>
          asegurado.apellidoPaterno === rest.asegurados[0].apellidoPaterno &&
          asegurado.apellidoMaterno === rest.asegurados[0].apellidoMaterno &&
          asegurado.nombre === rest.asegurados[0].nombre &&
          asegurado.relacionId === rest.asegurados[0].relacionId &&
          asegurado.sexoId === rest.asegurados[0].sexoId &&
          asegurado.fechaNacimiento === rest.asegurados[0].fechaNacimiento &&
          asegurado.cartaReconocimientoFile === rest.asegurados[0].cartaReconocimientoFile &&
          asegurado.certificadoFile === rest.asegurados[0].certificadoFile &&
          asegurado.actaNacimientoFile === rest.asegurados[0].actaNacimientoFile &&
          asegurado.fechaCartaReconocimiento === rest.asegurados[0].fechaCartaReconocimiento &&
          asegurado.numCertificado === rest.asegurados[0].numCertifiicado
      );
      if (!existingAsegurado) {
        existingItem.asegurados.push({
          apellidoPaterno: rest.asegurados[0].apellidoPaterno,
          apellidoMaterno: rest.asegurados[0].apellidoMaterno,
          nombre: rest.asegurados[0].nombre,
          relacionId: rest.asegurados[0].relacionId,
          sexoId: rest.asegurados[0].sexoId,
          fechaNacimiento: rest.asegurados[0].fechaNacimiento,
          cartaReconocimientoFile: rest.asegurados[0].cartaReconocimientoFile,
          certificadoFile: rest.asegurados[0].certificadoFile,
          actaNacimientoFile: rest.asegurados[0].actaNacimientoFile,
          fechaCartaReconocimiento: rest.asegurados[0].fechaCartaReconocimiento,
          numCertificado: rest.asegurados[0].numCertifiicado,
        });
      }
    } else {
      const newItem = {
        tipoSolicitudId,
        motivoEndosoId,
        polizaId,
        observaciones,
        asegurados: [
          {
            apellidoPaterno: rest.asegurados[0].apellidoPaterno,
            apellidoMaterno: rest.asegurados[0].apellidoMaterno,
            nombre: rest.asegurados[0].nombre,
            relacionId: rest.asegurados[0].relacionId,
            sexoId: rest.asegurados[0].sexoId,
            fechaNacimiento: rest.asegurados[0].fechaNacimiento,
            cartaReconocimientoFile: rest.asegurados[0].cartaReconocimientoFile,
            certificadoFile: rest.asegurados[0].certificadoFile,
            actaNacimientoFile: rest.asegurados[0].actaNacimientoFile,
            fechaCartaReconocimiento: rest.asegurados[0].fechaCartaReconocimiento,
            numCertificado: rest.asegurados[0].numCertifiicado,
          },
        ],
      };
      if (tipoSolicitudId >= 4) {
        newItem.fechaBaja = curr.fechaBaja;
      }
      if (tipoSolicitudId <= 3) {
        newItem.fechaAlta = curr.fechaAlta;
      }
      acc.push(newItem);
    }
    return acc;
  }, []);
  return groupedData;
}
