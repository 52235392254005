import React from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CustomTypography from "../../../components/CustomTypography";
import { useDialog, useDialogUpdate } from "./DialogContext";
import FormContent from "./FormContent";
import { useStep, useStepUpdate } from "./StepContext";
import { Box } from "@mui/system";

const PopUpForm = () => {
  const open = useDialog();
  const toggleDialog = useDialogUpdate();
  const step = useStep();

  const handleClose = () => {
    toggleDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#fff",
          borderRadius: "2rem",
          minWidth: { xs: "95%", sm: "90%", md: "50%" },
          maxWidth: { xs: "95%", sm: "90%", md: "60%" },
          minHeight: { xs: "50%", sm: "50%", md: "50%", lg: "45%" },
          maxHeight: { xs: "95%", sm: "95%", md: "9S%" },
        },
      }}
    >
      {step === false ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            color: "#fff",
          }}
        >
          <ArrowBackIosNewOutlinedIcon />
        </IconButton>
      ) : (
        <></>
      )}

      <DialogTitle
        sx={{
          fontWeight: "bold",
          backgroundColor: "#73B87E",
          maxHeight: "10vh",
        }}
      >
        <CustomTypography
          variant="h4"
          component="header"
          color="white"
          sx={{ fontWeight: "bold" }}
          align="center"
          justifyContent="center"
        >
          Solicitud Individual
        </CustomTypography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: "1rem",
          top: "1rem",
          color: "#fff",
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <Box sx={{ height: "600", maxWidth: 1200, width: "100%", p: 4 }}>
        <FormContent />
      </Box>
    </Dialog>
  );
};

export default PopUpForm;
