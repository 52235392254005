import { auth } from "./AuthContext";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  // determine if authorized, from context
  const { currentUser } = auth;

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  //return auth ? <Outlet /> : <Navigate to="/login" />;
  return currentUser && currentUser.reloadUserInfo.mfaInfo.length > 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
