import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { auth } from "../../../context/AuthContext";

import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";
import dayjs from "dayjs";
import axios from "axios";
import PropTypes from "prop-types";

const RequestStatus = ({ id, onReturn }) => {
  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;
  const [solicitud, setSolicitud] = useState(null);
  const [tipoModificaciones, setTipoModificaciones] = useState("");

  useEffect(() => {
    async function fetchSolicitud() {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/solicitudes/" + id, {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      setSolicitud(result);
    }
    fetchSolicitud();
  }, []);

  useEffect(() => {
    if (solicitud?.motivoEndoso.id === 1) {
      setTipoModificaciones("Altas");
    } else if (solicitud?.motivoEndoso.id === 2) {
      setTipoModificaciones("Bajas");
    }
  }, [solicitud, solicitud?.motivoEndoso]);

  useEffect(() => {
    if (solicitud) {
      onReturn(solicitud);
    }
  }, [solicitud, onReturn]);

  return (
    <>
      <CustomBox
        width="90%"
        height={{ xs: window.innerHeight / 4 + 60 + "px", xxl: window.innerHeight / 4 - 10 + "px" }}
        mx="auto"
        position="relative"
        top="10rem"
      >
        <Container>
          <CustomTypography variant="h4" fontWeight="bold" mt={4} textAlign="center">
            Solicitud Enviada
          </CustomTypography>
          <CustomTypography
            variant="h5"
            fontWeight="bold"
            mt={2}
            textAlign="center"
            sx={{ color: "#00A7E1" }}
          >
            No. solicitud: {solicitud?.numSolicitud} | {solicitud?.asegurados?.length || 0}{" "}
            {tipoModificaciones} | Aseguradora {solicitud?.poliza.aseguradora.nombre} |{" "}
            {dayjs(solicitud?.createdAt).format("DD/MM/YYYY")}
          </CustomTypography>
          <CustomTypography variant="h5" fontWeight="bold" mt={2} textAlign="center">
            Tu alta estará lista en aproximadamente{" "}
            {solicitud?.poliza.razonSocial.cliente.tiempoEstimadoAplicacion} días, puedes consultar
            el certificado por correo o en el status de la solicitud.
          </CustomTypography>
        </Container>
      </CustomBox>
    </>
  );
};

RequestStatus.propTypes = {
  id: PropTypes.string.isRequired,
  onReturn: PropTypes.func.isRequired,
};

export default RequestStatus;
