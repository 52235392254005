import { Card } from "@mui/material";
import CustomBox from "components/CustomBox";
import CustomNavbar from "structures/CustomNavbar";
import Footer from "structures/Footer";
import ReviewTable from "./ReviewTable";
import PropTypes from "prop-types";
import History from "./History";

const { useLocation } = require("react-router-dom");

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReviewRequest = ({ id: propId }) => {
  const query = useQuery();
  const id = propId || query.get("id");

  return (
    <>
      <CustomNavbar tabValue={1} />
      <Card
        sx={{
          p: 1,
          mt: 20,
          mb: 4,
          height: "fill-available",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.9),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ReviewTable id={id} />
        <History id={id} />
      </Card>
      <CustomBox width="100%" position="relative" zIndex={2} bottom="1.625rem">
        <Footer />
      </CustomBox>
    </>
  );
};

export default ReviewRequest;

ReviewRequest.propTypes = {
  id: PropTypes.string,
};
