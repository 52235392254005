import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import Swal from "sweetalert2";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";

import Footer from "structures/Footer";
import LogoBox from "../LogoBox";

function PassReset() {
  const [email, setEmail] = useState("");
  const { resetPassword } = useAuth();

  const handleReset = async () => {
    try {
      await resetPassword(email);
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "Se ha enviado un correo para restablecer tu contraseña",
        timer: 5000,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se ha podido enviar el correo",
        timer: 5000,
      });
    }
  };
  return (
    <>
      <CustomBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundColor: "#039ECC",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <CustomBox
        px={1}
        width="100%"
        height="100vh"
        mx="auto"
        position="absolute"
        zIndex={2}
        top="-4rem"
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <LogoBox />
            <Card>
              <CustomBox pt={4} pb={3} px={3}>
                <CustomBox component="form" role="form">
                  <CustomBox mb={2}>
                    <CustomInput
                      type="email"
                      label="Correo"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CustomBox>

                  <CustomBox mt={4} mb={1}>
                    <CustomButton
                      component={Link}
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleReset}
                    >
                      Recuperar Contraseña
                    </CustomButton>
                  </CustomBox>
                  <CustomBox mt={3} mb={1} textAlign="center">
                    <CustomTypography variant="button" color="text">
                      <CustomTypography
                        component={Link}
                        to="/inicio"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Ingresar a Cuenta
                      </CustomTypography>
                    </CustomTypography>
                  </CustomBox>
                </CustomBox>
              </CustomBox>
            </Card>
          </Grid>
        </Grid>
      </CustomBox>
      <CustomBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <Footer light />
      </CustomBox>
    </>
  );
}

export default PassReset;
