import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";

import typography from "assets/theme/base/typography";

function Footer({ company, links, light }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link, key) => (
      <CustomBox
        key={link.name}
        component="li"
        pl={key === 0 ? 0 : 2}
        pr={key === links.length - 1 ? 0 : 2}
        lineHeight={1}
      >
        <Link href={link.href} target="_blank">
          <CustomTypography variant="button" fontWeight="bold" color={light ? "white" : "text"}>
            {link.name}
          </CustomTypography>
        </Link>
      </CustomBox>
    ));

  return (
    <Container>
      <CustomBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <CustomBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color={light ? "white" : "text"}
          fontSize={size.sm}
        >
          &copy; {new Date().getFullYear()},
          <CustomBox
            fontSize={size.md}
            color={light ? "white" : "text"}
            mb={-0.5}
            mx={0.25}
          ></CustomBox>
          <Link href={href} target="_blank">
            <CustomTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
              &nbsp;{name}&nbsp;
            </CustomTypography>
          </Link>
        </CustomBox>
        <CustomBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
        </CustomBox>
      </CustomBox>
    </Container>
  );
}

Footer.defaultProps = {
  company: { href: "https://www.interproteccion.com.mx/", name: "Interprotección" },
  links: [{ href: "mailto:avisosgmm@inter.mx", name: "Contacta Atención a Clientes" }],
  light: false,
};

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.shape),
  light: PropTypes.bool,
};

export default Footer;
