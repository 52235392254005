import { auth } from "./firebase";
import { useEffect } from "react";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import routes from "routes";

import SignIn from "pages/Authentication/SignIn";
import PassReset from "pages/Authentication/PassReset";
import PrivateRoute from "context/PrivateRoute";
import Dashboard from "pages/Dashboard";
import RequestSection from "pages/RequestSection";
import MassiveRequest from "pages/Massive_Request";
import MFASetup from "./pages/Authentication/MFA";
import RequestHistory from "pages/RequestHistory";
import Summary from "pages/RequestSummary";
import { StepProvider } from "pages/RequestSection/sections/StepContext";
import { DialogProvider } from "pages/RequestSection/sections/DialogContext";
import SummaryTable from "pages/SummaryTable";
import { DataProvider } from "pages/RequestSection/sections/DataContext";
import MassiveSummaryTable from "pages/MassiveSummaryTable";
import { UserProvider } from "context/UserContext";
import Notifications from "pages/Notifications";
import { ErrorProvider } from "pages/RequestSection/sections/ErrorContext";
import { IndexProvider } from "./pages/RequestSection/sections/IndexContext";
import { MassiveDialogProvider } from "pages/MassiveSummaryTable/sections/MassiveDialogContext";
import { useInactivityTimer } from "./context/useInactivityTimer";
import ReviewRequest from "pages/RequestHistory/sections/ReviewRequest";

function MainContent() {
  const { pathname } = useLocation();
  const { logout } = useAuth();

  const [isInactive] = useInactivityTimer(600000, pathname); // 10 minutes, can be adjusted

  useEffect(() => {
    if (isInactive) {
      logout();
    }
  }, [isInactive, logout]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    auth.currentUser;
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="/" element={<SignIn />} />
      <Route path="/home" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="/recuperar-contrasena" element={<PassReset />} />
      <Route path="/mfa-setup" element={<MFASetup />} />
      <Route path="/inicio" element={<SignIn />} />
      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="/opciones-solicitud" element={<PrivateRoute />}>
        <Route index element={<RequestSection />} />
      </Route>
      <Route path="/solicitud-masiva" element={<PrivateRoute />}>
        <Route index element={<MassiveRequest />} />
      </Route>
      <Route path="/consultar-solicitud" element={<PrivateRoute />}>
        <Route index element={<RequestHistory />} />
      </Route>
      <Route path="/tabla-individual" element={<PrivateRoute />}>
        <Route index element={<SummaryTable />} />
      </Route>
      <Route path="/tabla-masiva" element={<PrivateRoute />}>
        <Route index element={<MassiveSummaryTable />} />
      </Route>
      <Route path="/resumen-solicitud" element={<PrivateRoute />}>
        <Route index element={<Summary />} />
      </Route>
      <Route path="/notificaciones" element={<PrivateRoute />}>
        <Route index element={<Notifications />} />
      </Route>
      <Route path="/resumen-orden" element={<PrivateRoute />}>
        <Route index element={<ReviewRequest />} />
      </Route>
      <Route path="*" element={<Navigate to="/inicio" />} />
    </Routes>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <UserProvider>
          <DataProvider>
            <ErrorProvider>
              <IndexProvider>
                <DialogProvider>
                  <MassiveDialogProvider>
                    <StepProvider>
                      <MainContent />
                    </StepProvider>
                  </MassiveDialogProvider>
                </DialogProvider>
              </IndexProvider>
            </ErrorProvider>
          </DataProvider>
        </UserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
