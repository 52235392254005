import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../../../context/AuthContext";
import axios from "axios";
import { Table } from "antd";
import RequestProgress from "pages/RequestSection/sections/RequestProgress";
import ItemCounter from "pages/RequestSection/sections/ItemCounter";
import StepProgress from "pages/RequestSection/sections/StepProgress";
import CustomButton from "../../../components/CustomButton";

const ReviewTable = ({ id }) => {
  const [solicitud, setSolicitud] = useState(null);
  const [solicitudArr, setSolicitudArr] = useState([]);
  const generateSignedURLEndpoint = process.env.REACT_APP_GENERATE_SIGNED_URL_FUNCTION;

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

  useEffect(() => {
    async function fetchSolicitud() {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/solicitudes/" + id, {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      setSolicitud(result);
    }
    fetchSolicitud();
  }, []);

  // if there is more than 1 asegurado, create a new solicitud for each asegurado, append it to solicitudArr
  useEffect(() => {
    if (solicitud && solicitud?.asegurados.length > 1) {
      solicitud.asegurados.forEach((asegurado) => {
        const newSolicitud = { ...solicitud, asegurados: [asegurado] };
        setSolicitudArr((prev) => [...prev, newSolicitud]);
      });
    } else if (solicitud) {
      setSolicitudArr([solicitud]);
    }
  }, [solicitud]);

  const downloadFile = async (fileName) => {
    const userToken = await auth.currentUser.getIdToken(true);
    const endpoint = generateSignedURLEndpoint + "?file=" + fileName;

    const { data } = await axios.get(endpoint, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });

    // Assuming the server returns a JSON with url field
    const fileURL = data.url;

    // Force download the file
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", ""); // Add a filename if needed
    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    { title: "", key: "editar", align: "center" },
    {
      title: "Tipo de O.T.",
      key: "tipoSolicitud",
      align: "center",
      render: (solicitud) => {
        switch (solicitud.tipoSolicitud.id) {
          case 1:
            return "Alta con reconocimiento de antigüedad";
          case 2:
            return "Alta sin reconocimiento de antigüedad";
          case 3:
            return "Alta de recién nacido";
          case 4:
            return "Baja de asegurado";
          default:
            return null;
        }
      },
    },
    {
      title: "Tipo de endoso",
      key: "motivoEndoso",
      align: "center",
      render: (solicitud) => {
        switch (solicitud.motivoEndoso.id) {
          case 1:
            return "Alta";
          case 2:
            return "Baja";
          default:
            return null;
        }
      },
    },
    {
      title: "No. Certificado",
      key: "numCertificado",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          return asegurado.numCertifiicado;
        });
      },
    },
    {
      title: "Nombre",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          return asegurado.nombre;
        });
      },
    },
    {
      title: "Apellido Paterno",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          return asegurado.apellidoPaterno;
        });
      },
    },
    {
      title: "Apellido Materno",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          return asegurado.apellidoMaterno;
        });
      },
    },
    {
      title: "Parentesco",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          switch (asegurado.relacion.id) {
            case 1:
              return "Titular";
            case 2:
              return "Cónyuge";
            case 3:
              return "Hijo";
            default:
              return null;
          }
        });
      },
    },
    {
      title: "Sexo",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado) => {
          switch (asegurado.sexo.id) {
            case 1:
              return "Hombre";
            case 2:
              return "Mujer";
            default:
              return null;
          }
        });
      },
    },
    {
      title: "Fecha de Nacimiento",
      key: "asegurados",
      align: "center",
      render: (solicitud) => {
        return solicitud.asegurados.map((asegurado, index) => {
          if (asegurado.fechaNacimiento) {
            return (
              <span key={index}>
                {new Date(asegurado.fechaNacimiento).toISOString().slice(0, 10)}
              </span>
            );
          } else {
            return <span key={index}>No encontrada</span>;
          }
        });
      },
    },
    {
      title: "Fecha de Alta",
      key: "fechaAlta",
      align: "center",
      render: (solicitud) => {
        const date =
          solicitud.tipoSolicitud.id === 4 || solicitud.tipoSolicitud.id === 5 ? (
            <span>No aplica</span>
          ) : (
            solicitud.fechaAlta
          );
        if (solicitud.tipoSolicitud.id === 4 || solicitud.tipoSolicitud.id === 5) {
          return <span>No aplica</span>;
        } else {
          return new Date(date).toISOString().slice(0, 10);
        }
      },
    },
    {
      title: "Fecha de Baja",
      key: "fechaBaja",
      align: "center",
      render: (solicitud) => {
        const date =
          solicitud.tipoSolicitud.id === 1 ||
          solicitud.tipoSolicitud.id === 2 ||
          solicitud.tipoSolicitud.id === 3 ? (
            <span>No aplica</span>
          ) : (
            solicitud.fechaBaja
          );
        if (
          solicitud.tipoSolicitud.id === 1 ||
          solicitud.tipoSolicitud.id === 2 ||
          solicitud.tipoSolicitud.id === 3
        ) {
          return <span>No aplica</span>;
        } else {
          return new Date(date).toISOString().slice(0, 10);
        }
      },
    },
    {
      title: "Fecha de carta",
      key: ["asegurados", 0, "fechaCartaReconocimiento"],
      align: "center",
      render: (fechaCartaReconocimiento, record) => {
        if (record.tipoSolicitud.id === 1 && record.asegurados[0].fechaCartaReconocimiento) {
          return new Date(record.asegurados[0].fechaCartaReconocimiento).toISOString().slice(0, 10);
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Certificado de antigüedad",
      key: "certificadoFileName",
      align: "center",
      render: (solicitud) => {
        if (solicitud.tipoSolicitud.id === 1) {
          return solicitud.asegurados.map((asegurado, index) => {
            return (
              <div key={index} style={{ display: "inline-flex", alignItems: "center" }}>
                <CustomButton onClick={() => downloadFile(asegurado.certificadoFile)}>
                  Certificado de Antigüedad.pdf
                </CustomButton>
              </div>
            );
          });
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Carta de reconocimiento de antigüedad",
      key: "cartaFileName",
      align: "center",
      render: (solicitud) => {
        if (solicitud.tipoSolicitud.id === 1) {
          return solicitud.asegurados.map((asegurado, index) => {
            return (
              <div key={index} style={{ display: "inline-flex", alignItems: "center" }}>
                <CustomButton onClick={() => downloadFile(asegurado.cartaReconocimientoFile)}>
                  Carta de Reconocimiento.pdf
                </CustomButton>
              </div>
            );
          });
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Acta de nacimiento",
      key: "actaNacimientoFileName",
      align: "center",
      render: (solicitud) => {
        if (solicitud.tipoSolicitud.id === 3) {
          return solicitud.asegurados.map((asegurado, index) => {
            return (
              <div key={index} style={{ display: "inline-flex", alignItems: "center" }}>
                <CustomButton onClick={() => downloadFile(asegurado.actaNacimientoFile)}>
                  Acta de Nacimiento.pdf
                </CustomButton>
              </div>
            );
          });
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Aseguradora",
      key: "poliza",
      align: "center",
      render: (solicitud) => {
        return solicitud.poliza.aseguradora.nombre;
      },
    },
    {
      title: "",
      key: "borrar",
      align: "center",
    },
  ];

  return (
    <>
      <RequestProgress solicitud={solicitud} />
      <ItemCounter externalCount={solicitud?.asegurados.length} />
      <StepProgress externalStep={solicitud?.status.id} />
      {solicitud && (
        <Table
          dataSource={solicitudArr}
          columns={columns}
          pagination={false}
          rowKey="id"
          style={{ width: "95%", marginTop: "0rem", overflowX: "auto" }}
        />
      )}
    </>
  );
};

export default ReviewTable;

ReviewTable.propTypes = {
  id: PropTypes.string,
};
