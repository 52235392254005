export default function MassiveDataFormat(data, fileData) {
  const fechaAlta =
    fileData.MOVIMIENTO === "A" || fileData.MOVIMIENTO === "B" || fileData.MOVIMIENTO === "C"
      ? formatDate(fileData.FECHADEMOVIMIENTO)
      : "No aplica";

  const fechaBaja =
    fileData.MOVIMIENTO === "D" ? formatDate(fileData.FECHADEMOVIMIENTO) : "No aplica";

  const processedData = {
    polizaId: fileData.NUMEROPOLIZA,
    cliente: data.cliente,
    observaciones: data.observaciones,
    fechaAlta,
    fechaBaja,
    motivoEndosoId: fileData.MOVIMIENTO === "D" ? 2 : 1,
    tipoSolicitudId: fileData.MOVIMIENTO,
    //numCertifiicado: fileData.CERTIFICADO,
    asegurados: [
      {
        fechaNacimiento: formatDate(fileData.FECHADENACIMIENTO),
        nombre: fileData.NOMBRE,
        apellidoPaterno: fileData.APELLIDOPATERNO,
        apellidoMaterno: fileData.APELLIDOMATERNO,
        relacionId:
          fileData.PARENTESCO === "TITULAR"
            ? 1
            : fileData.PARENTESCO === "CONYUGE"
            ? 2
            : fileData.PARENTESCO === "HIJO"
            ? 3
            : "",
        sexoId: fileData.GENERO === "FEMENINO" ? 1 : fileData.GENERO === "MASCULINO" ? 2 : "",

        actaNacimientoFileName: "",
        actaNacimientoFile: "",
        certificadoFileName: "",
        certificadoFile: "",
        cartaReconocimientoFileName: "",
        cartaReconocimientoFile: "",
        fechaCartaReconocimiento: "",
        numCertifiicado: fileData.CERTIFICADO,
      },
    ],
  };

  return processedData;
}

function formatDate(fecha) {
  const dateValue = fecha;
  const milliseconds = (dateValue - 25569) * 86400 * 1000; // Convert Excel date value to Unix timestamp in milliseconds
  const date = new Date(milliseconds);
  const dateString = date.toISOString().slice(0, 10);
  const [year, month, day] = dateString.split("-");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
