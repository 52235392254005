import { Space, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useData, useDataUpdate } from "../../RequestSection/sections/DataContext";
import ItemCounter from "pages/RequestSection/sections/ItemCounter";
import StepProgress from "pages/RequestSummary/sections/StepProgress";
import { Alert, Grid, IconButton, Paper, Popover, Snackbar } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CustomTypography from "components/CustomTypography";
import CustomButton from "components/CustomButton";
import { useDialog } from "pages/RequestSection/sections/DialogContext";
import { auth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import Swal from "sweetalert2";
import RequestProgress from "pages/RequestSection/sections/RequestProgress";
import MassivePopUpForm from "pages/RequestSection/sections/MassivePopUpForm";
import axios from "axios";
import "./styles.css";
import MassiveDataProcess from "pages/RequestSection/sections/MassiveDataProcess";
import MassiveCounts from "pages/RequestSection/sections/MassiveCounts";
import { useError } from "pages/RequestSection/sections/ErrorContext";
import CustomBox from "components/CustomBox";
import ErrorList from "./ErrorList";
import { useIndexUpdate } from "pages/RequestSection/sections/IndexContext";
import MassivePopUpFormEdit from "./MassivePopUpFormEdit";
import { useMassiveDialog, useMassiveDialogUpdate } from "./MassiveDialogContext";
import { useDialogUpdate } from "pages/RequestSection/sections/DialogContext";
import { ErrorOutline } from "@mui/icons-material";
import MassiveDataGrouping from "./MassiveDataGrouping";

const MassiveDynamicTable = () => {
  const data = useData();
  const updateData = useDataUpdate();
  const open = useDialog();
  const toggleDialog = useDialogUpdate();
  const openEdit = useMassiveDialog();
  const toggleMassiveDialog = useMassiveDialogUpdate();
  const editIndex = useIndexUpdate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableData, setTableData] = useState([]);
  const errorList = useError();
  const [errorMessages, setErrorMessages] = useState([]);
  const [aseguradora, setAseguradora] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [backdropHeight, setBackdropHeight] = useState("90vh");
  const [boxHeight, setBoxHeight] = useState("90vh");
  const [responses, setResponses] = useState([]);
  const [requestIds, setRequestIds] = useState([]);

  const [sendDisabled, setSendDisabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const backdropHeight = windowHeight - navbarHeight - footerHeight - 160;
      const boxHeight = windowHeight - navbarHeight - footerHeight - 175;
      setBackdropHeight(`${backdropHeight}px`);
      setBoxHeight(`${boxHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRetry = () => {
    setIsLoading(true);
    //clear all data from table
    updateData([]);
    //clear error list
    errorList.splice(0, errorList.length);
    setTimeout(() => {
      toggleDialog();
      setIsLoading(false);
    }, 1000);
  };

  // Enable or disable button if all rows that require files have files uploaded
  // Also takes in consideration that table is empty or contains errors
  useEffect(() => {
    const altaConReconocimientoAntiguedad = tableData.filter(
      (item) => item.tipoSolicitudId === "A"
    );
    const altaRecienNacido = tableData.filter((item) => item.tipoSolicitudId === "C");

    if (altaConReconocimientoAntiguedad.length > 0 || altaRecienNacido.length > 0) {
      const missingCertificado = altaConReconocimientoAntiguedad.some(
        (item) => item.asegurados[0].certificadoFileName === ""
      );

      const missingCarta = altaConReconocimientoAntiguedad.some(
        (item) => item.asegurados[0].cartaReconocimientoFileName === ""
      );

      const missingActa = altaRecienNacido.some(
        (item) => item.asegurados[0].actaNacimientoFileName === ""
      );

      if (missingCertificado || missingCarta || missingActa) {
        setSendDisabled(true);
      } else {
        setSendDisabled(false);
      }
    } else if (tableData.length === 0) {
      setSendDisabled(true);
    } else if (errorList.length > 0) {
      setSendDisabled(true);
    } else {
      setSendDisabled(false);
    }
  }, [tableData]);

  useEffect(() => {
    const newData = data.map((item, index) => {
      return {
        ...item,
        key: index.toString(),
      };
    });
    setTableData(newData);
  }, [data]);

  const onSubmit = async () => {
    const dataToSend = MassiveDataProcess(tableData);
    const groupedData = MassiveDataGrouping(dataToSend);
    const newErrorMessages = [];

    const hasEmptyCertificado = tableData.some(
      (row) => row.tipoSolicitudId === 1 && row.certificadoFileName === ""
    );
    if (hasEmptyCertificado) {
      const emptyCertificadoRow = tableData.findIndex(
        (row) => row.tipoSolicitudId === 1 && row.certificadoFileName === ""
      );
      newErrorMessages.push("Falta subir certificado de antiguedad en una o más filas.");
    }

    const hasEmptyCarta = tableData.some(
      (row) => row.tipoSolicitudId === 1 && row.cartaReconocimientoFileName === ""
    );
    if (hasEmptyCarta) {
      const emptyCartaRow = tableData.findIndex(
        (row) => row.tipoSolicitudId === 1 && row.cartaReconocimientoFileName === ""
      );
      newErrorMessages.push(
        "Falta subir carta de reconocimiento de antiguedad en una o más filas."
      );
    }

    const hasEmptyActa = tableData.some(
      (row) => row.tipoSolicitudId === 3 && row.actaNacimientoFileName === ""
    );
    if (hasEmptyActa) {
      const emptyActaRow = tableData.findIndex(
        (row) => row.tipoSolicitudId === 3 && row.actaNacimientoFileName === ""
      );
      newErrorMessages.push("Falta subir acta de nacimiento en una o más filas.");
    }

    if (newErrorMessages.length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    }

    // Submit form data

    try {
      const serverResponse = await sendData(groupedData);

      // if all requests were successful, show success message
      // check that all responses have status 201 (created) and that the number of responses is equal to the number of requests
      if (
        serverResponse.every((response) => response.status === 201) &&
        serverResponse.length === requestIds.length
      ) {
        Swal.fire({
          title: "¡Solicitud enviada!",
          text: "Tu solicitud ha sido enviada con éxito.",
          icon: "success",
          confirmButtonColor: "#73B87E",
          timer: 3000000,
        }).then(() => {
          // send user to request summary page with all request ids in the url
          window.location = "/resumen-solicitud?id=" + requestIds.join(",");
          setIsLoading(false);
        });
      } else if (serverResponse.some((response) => response.status === 201)) {
        Swal.fire({
          title: "¡Solicitud enviada!",
          text: "Tu solicitud ha sido enviada con éxito, pero algunos envíos fallaron. Revisar resumen de orden para validar.",
          icon: "warning",
          confirmButtonColor: "#73B87E",
          timer: 30000,
        }).then(() => {
          // send user to request summary page with successful request ids in the url
          const successfulIds = responses
            .filter((response) => response.status === 201)
            .map((response) => response.data.id);
          window.location = "/resumen-solicitud?id=" + successfulIds.join(",");
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        setResponses([]);
        setRequestIds([]);
        Swal.fire({
          title: "Error",
          text: `Hubo un error al enviar la solicitud.`,
          icon: "error",
          confirmButtonColor: "#73B87E",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setResponses([]);
      setRequestIds([]);
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Hubo un error al enviar la solicitud. Por favor, reintenta.",
        icon: "error",
        confirmButtonColor: "#73B87E",
      });
    }
  };

  const updateTableData = (key, property, value) => {
    setTableData((oldValues) => {
      const index = oldValues.findIndex((item) => item.key === key);
      const updatedData = [...oldValues];
      updatedData[index] = {
        ...updatedData[index],
        [property]: value,
      };
      updateData(updatedData[index], true, index);
      return updatedData;
    });
  };

  //Obtain user id from UserContext and use it to fetch polizas and aseguradoras
  const user = useUser();
  const clientIdentification = user.clientID;

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;
  const [polizaValues, setPolizaValues] = useState([]);

  useEffect(() => {
    const fetchPolizaValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/razones-sociales/" + clientIdentification + "/polizas", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setPolizaValues(response.data);
          setAseguradora(response.data[0].aseguradora.nombre);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchPolizaValues();
  }, [clientIdentification]);

  const [aseguradoras, setAseguradoras] = useState([]);
  useEffect(() => {
    const aseguradorasSet = new Set();
    polizaValues.forEach((poliza) => {
      aseguradorasSet.add({
        id: poliza.aseguradora.id,
        nombre: poliza.aseguradora.nombre,
        numeroPoliza: poliza.numeroPoliza, // Add numeroPoliza to object
      });
    });
    setAseguradoras(Array.from(aseguradorasSet));
  }, [polizaValues]);

  const aseguradorasWithPolicy = polizaValues.map((poliza) => {
    return {
      numeroPoliza: poliza.numeroPoliza,
      aseguradoraNombre: poliza.aseguradora.nombre,
    };
  });

  const handleSendRequest = async () => {
    const dataToSend = MassiveDataProcess(tableData);
    // show error message if file is not uploaded for fields that require files
    const altaConReconocimientoAntiguedad = tableData.find((item) => item.tipoSolicitudId === "A");
    const altaRecienNacido = tableData.find((item) => item.tipoSolicitudId === "C");

    if (altaConReconocimientoAntiguedad) {
      if (
        altaConReconocimientoAntiguedad.certificadoFile === "" ||
        altaConReconocimientoAntiguedad.certificadoFile === null
      ) {
        Swal.fire({
          title: "¡Error!",
          text: "Por favor, subir el certificado de antigüedad en solicitudes de alta con reconocimiento de antigüedad.",
          icon: "error",
          confirmButtonText: "Regresar",
          confirmButtonColor: "#73B87E",
        });
        return;
      }
      if (
        altaConReconocimientoAntiguedad.cartaReconocimientoFile === "" ||
        altaConReconocimientoAntiguedad.cartaReconocimientoFile === null
      ) {
        Swal.fire({
          title: "¡Error!",
          text: "Por favor, subir la carta de reconocimiento de antigüedad en solicitudes de alta con reconocimiento de antigüedad.",
          icon: "error",
          confirmButtonText: "Regresar",
          confirmButtonColor: "#73B87E",
        });
        return;
      }
    }

    if (altaRecienNacido) {
      if (
        altaRecienNacido.actaNacimientoFile === "" ||
        altaRecienNacido.actaNacimientoFile === null
      ) {
        Swal.fire({
          title: "¡Error!",
          text: "Por favor, subir el acta de nacimiento en solicitudes de alta de recién nacidos.",
          icon: "error",
          confirmButtonText: "Regresar",
          confirmButtonColor: "#73B87E",
        });
        return;
      }
    }

    //if there is no data in the table, show error message
    if (tableData.length === 0) {
      Swal.fire({
        title: "¡Error!",
        text: "No existen datos para enviar.",
        icon: "error",
        confirmButtonText: "Regresar",
        confirmButtonColor: "#CACACA",
        customClass: {
          container: "my-swal",
          confirmButton: "my-swal-cancel-button",
          actions: "my-swal-actions",
        },
      });
      return;
    }

    //if there is data in the table, show confirmation message
    else if (tableData.length > 0) {
      Swal.fire({
        title: "¿Estás seguro que la información es la correcta?",
        showCancelButton: true,
        cancelButtonText: "Regresar",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#73B87E",
        cancelButtonColor: "#CACACA",
        reverseButtons: true,
        customClass: {
          container: "my-swal",
          confirmButton: "my-swal-confirm-button",
          cancelButton: "my-swal-cancel-button",
          actions: "my-swal-actions",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit();
        }
      });
    }
  };

  const sendData = async (dataToSend) => {
    //create a copy of dataToSend to avoid mutating the original array and remove delete files from tipoSolicitudId that don't require them
    const dataToSendCopy = dataToSend.map((item) => {
      item.asegurados.forEach((asegurado) => {
        if (item.tipoSolicitudId === 1) {
          delete asegurado.actaNacimientoFile;
        }
        if (item.tipoSolicitudId === 2) {
          delete asegurado.actaNacimientoFile;
          delete asegurado.cartaReconocimientoFile;
          delete asegurado.certificadoFile;
          delete asegurado.fechaCartaReconocimiento;
        }
        if (item.tipoSolicitudId === 3) {
          delete asegurado.cartaReconocimientoFile;
          delete asegurado.fechaCartaReconocimiento;
          delete asegurado.certificadoFile;
        }
        if (item.tipoSolicitudId === 4) {
          delete asegurado.actaNacimientoFile;
          delete asegurado.certificadoFile;
          delete asegurado.cartaReconocimientoFile;
          delete asegurado.fechaCartaReconocimiento;
        }
      });
      return item;
    });
    setIsLoading(true);
    const userToken = await auth.currentUser.getIdToken(true);
    try {
      for (let i = 0; i < dataToSendCopy.length; i++) {
        const response = await axios.post(
          url + "/solicitudes",
          JSON.stringify(dataToSendCopy[i]), // Convert data to JSON string
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        );
        // append response to responses array
        responses.push(response);
        // append request id to requestIds array
        requestIds.push(response.data.id);
      }
      setIsLoading(false);
      return responses;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return error;
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openInfo = Boolean(anchorEl);

  const deleteByIndex = (index) => {
    updateData(data.filter((_, row) => row !== index));
  };

  const columns = [
    {
      title: "",
      key: "editar",
      align: "center",
      render: (_, record, index) => (
        <Space size="middle">
          <i
            className="fas fa-edit"
            style={{
              color: "#73B87E",
              cursor: "pointer",
            }}
            onClick={(e) => {
              editIndex(index);
              toggleMassiveDialog();
            }}
          ></i>
        </Space>
      ),
    },
    {
      title: "Tipo de O.T.",
      dataIndex: "tipoSolicitudId",
      key: "tipoSolicitudId",
      align: "center",
      render: (tipoSolicitudId) => {
        let text = "";
        switch (tipoSolicitudId) {
          case "A":
            text = "Alta con reconocimiento de antigüedad";
            break;
          case "B":
            text = "Alta sin reconocimiento de antigüedad";
            break;
          case "C":
            text = "Alta de recién nacidos";
            break;
          case "D":
            text = "Baja de asegurado";
            break;
          default:
            text = "";
            break;
        }
        return text;
      },
    },
    {
      title: "Tipo de endoso",
      dataIndex: "motivoEndosoId",
      key: "motivoEndosoId",
      align: "center",
      render: (motivoEndosoId, record) => {
        let text = "";
        if (
          record.tipoSolicitudId === "A" ||
          record.tipoSolicitudId === "B" ||
          record.tipoSolicitudId === "C"
        ) {
          text = "Alta de titular o dependiente";
        } else if (record.tipoSolicitudId === "D") {
          text = "Baja de titular o dependiente";
        }
        return text;
      },
    },
    {
      title: "No. Certificado",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => asegurados.map((asegurado) => asegurado.numCertifiicado).join(),
    },
    {
      title: "Nombre",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => asegurados.map((asegurado) => asegurado.nombre).join(),
    },
    {
      title: "Apellido Paterno",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => asegurados.map((asegurado) => asegurado.apellidoPaterno).join(),
    },
    {
      title: "Apellido Materno",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => asegurados.map((asegurado) => asegurado.apellidoMaterno).join(),
    },
    {
      title: "Parentesco",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => {
        let text = "";
        asegurados.forEach((asegurado) => {
          switch (asegurado.relacionId) {
            case 1:
              text += "TITULAR, ";
              break;
            case 2:
              text += "CÓNYUGE, ";
              break;
            case 3:
              text += "HIJO, ";
              break;
            default:
              text += "";
              break;
          }
        });
        return text.slice(0, -2);
      },
    },
    {
      title: "Sexo",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => {
        let text = "";
        asegurados.forEach((asegurado) => {
          switch (asegurado.sexoId) {
            case 1:
              text += "FEMENINO, ";
              break;
            case 2:
              text += "MASCULINO, ";
              break;
            default:
              text += "";
              break;
          }
        });
        return text.slice(0, -2);
      },
    },
    {
      title: "Fecha de Nacimiento",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => asegurados.map((asegurado) => asegurado.fechaNacimiento).join(),
    },
    {
      title: "Fecha de alta",
      dataIndex: "fechaAlta",
      key: "fechaAlta",
      align: "center",
      render: (fechaAlta, record) => {
        if (record.tipoSolicitudId === "A" || record.tipoSolicitudId === "B") {
          return fechaAlta ? <span>{fechaAlta}</span> : <span>Agregar Fecha</span>;
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Fecha de baja",
      dataIndex: "fechaBaja",
      key: "fechaBaja",
      align: "center",
      render: (fechaBaja, record) => {
        if (record.tipoSolicitudId === "D") {
          return fechaBaja ? <span>{fechaBaja}</span> : <span>Agregar Fecha</span>;
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Fecha de carta",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (_, record, index) => {
        // if tipoSolicitudId is alta con reconocimiento de antiguedad, show fechaCartaReconocimiento
        // if fechaCartaReconocimiento is null, show edit icon and allow user to add date in pop up form
        if (record.tipoSolicitudId === "A") {
          return record.asegurados[0].fechaCartaReconocimiento ? (
            <span>{record.asegurados[0].fechaCartaReconocimiento}</span>
          ) : (
            <i
              className="fas fa-edit"
              style={{
                color: "#73B87E",
                cursor: "pointer",
              }}
              onClick={(e) => {
                editIndex(index);
                toggleMassiveDialog();
              }}
            ></i>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },

    {
      title: "Certificado de antiguedad",
      dataIndex: ["asegurados", 0, "certificadoFileName"],
      key: ["asegurados", 0, "certificadoFileName"],
      align: "center",
      render: (certificadoFileName, record) => {
        if (record.tipoSolicitudId === "A") {
          let fileURL = null;
          if (record.asegurados[0].certificadoFile) {
            const byteCharacters = atob(record.asegurados[0].certificadoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {certificadoFileName === "" || certificadoFileName === undefined ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update certificadoFileName and certificadoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].certificadoFileName = file.name;
                          newAsegurados[0].certificadoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {certificadoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].certificadoFileName = "";
                      newAsegurados[0].certificadoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                        cursor: "pointer",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Carta de reconocimiento de antiguedad",
      dataIndex: ["asegurados", 0, "cartaFileName"],
      key: ["asegurados", 0, "cartaFileName"],
      align: "center",
      render: (cartaReconocimientoFileName, record) => {
        if (record.tipoSolicitudId === "A") {
          let fileURL = null;
          if (record.asegurados[0].cartaReconocimientoFile) {
            const byteCharacters = atob(record.asegurados[0].cartaReconocimientoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {cartaReconocimientoFileName === "" || cartaReconocimientoFileName === undefined ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update cartaReconocimientoFileName and cartaReconocimientoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].cartaFileName = file.name;
                          newAsegurados[0].cartaReconocimientoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {cartaReconocimientoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].cartaFileName = "";
                      newAsegurados[0].cartaReconocimientoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Acta de nacimiento",
      dataIndex: ["asegurados", 0, "actaNacimientoFileName"],
      key: ["asegurados", 0, "actaNacimientoFileName"],
      align: "center",
      render: (actaNacimientoFileName, record) => {
        if (record.tipoSolicitudId === "C") {
          let fileURL = null;
          if (record.asegurados[0].actaNacimientoFile) {
            const byteCharacters = atob(record.asegurados[0].actaNacimientoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {actaNacimientoFileName === "" || actaNacimientoFileName === undefined ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update actaNacimientoFileName and actaNacimientoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].actaNacimientoFileName = file.name;
                          newAsegurados[0].actaNacimientoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {actaNacimientoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].actaNacimientoFileName = "";
                      newAsegurados[0].actaNacimientoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Aseguradora",
      dataIndex: "aseguradora",
      key: "aseguradora",
      align: "center",
      render: (asegurado) => {
        return <span>{aseguradora}</span>;
      },
    },
    {
      title: "",
      key: "borrar",
      align: "center",
      render: (_, record, index) => (
        <Space size="middle">
          <i
            className="fas fa-trash-alt"
            style={{
              color: "#C72222",
              cursor: "pointer",
            }}
            //delete row by index from table and from localData array (state) using filter method
            onClick={() => {
              Swal.fire({
                title: "¿Está seguro que desea eliminar el registro?",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#E62530",
                cancelButtonColor: "#CACACA",
                customClass: {
                  container: "my-swal",
                  confirmButton: "my-swal-confirm-button",
                  cancelButton: "my-swal-cancel-button",
                  actions: "my-swal-actions",
                },
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteByIndex(index);
                }
              });
            }}
          ></i>
        </Space>
      ),
    },
  ];

  return (
    <>
      {isLoading === true ? (
        <>
          <CustomBox sx={{ height: boxHeight }} />
          <Backdrop
            sx={{
              height: backdropHeight,
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                marginTop: "-50px",
                marginLeft: "-50px",
              }}
            />
          </Backdrop>
        </>
      ) : (
        <>
          <RequestProgress />
          <ItemCounter />
          <StepProgress />
          {errorMessages.length > 0 && (
            <Snackbar
              open={errorMessages.length > 0}
              autoHideDuration={6000}
              onClose={() => {
                setErrorMessages([]);
              }}
              sx={{
                position: "fixed",
                bottom: "16px",
                left: "50%",
                transform: "translateX(5%)",
                zIndex: 9999,
              }}
            >
              <Alert
                onClose={() => {
                  setErrorMessages([]);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessages.map((message, index) => (
                  <CustomBox key={index} sx={{ display: "block" }}>
                    {message}
                  </CustomBox>
                ))}
              </Alert>
            </Snackbar>
          )}

          {/* ... */}
          {errorList.length > 0 ? (
            <CustomTypography
              sx={{
                color: "error.main",
                fontSize: { xs: "0.8rem", sm: "1.2rem" },
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <ErrorOutline sx={{ mr: 1 }} />
              Algunos campos no son válidos. Por favor, revisa los datos e intenta de nuevo.
            </CustomTypography>
          ) : (
            <Table
              dataSource={tableData}
              columns={columns}
              style={{ width: "95%", marginTop: "0rem", overflowX: "auto" }}
            >
              <Table.Column title="" dataIndex="editar" key="editar" />
              <Table.Column
                title="Tipo de O.T."
                dataIndex="tipoSolicitudId"
                key="tipoSolicitudId"
              />
              <Table.Column
                title="Tipo de endoso"
                dataIndex="motivoEndosoId"
                key="motivoEndosoId"
              />
              <Table.Column
                title="No. Certificado"
                dataIndex="numCertifiicado"
                key="numCertifiicado"
              />
              <Table.Column title="Nombre" dataIndex="nombre" key="nombre" />
              <Table.Column
                title="Apellido Paterno"
                dataIndex="apellidoPaterno"
                key="apellidoPaterno"
              />
              <Table.Column
                title="Apellido Materno"
                dataIndex="apellidoMaterno"
                key="apellidoMaterno"
              />
              <Table.Column title="Relacion" dataIndex="relacionId" key="relacionId" />
              <Table.Column title="Sexo" dataIndex="sexoId" key="sexoId" />
              <Table.Column
                title="Fecha de Nacimiento"
                dataIndex="fechaNacimiento"
                key="fechaNacimiento"
              />
              <Table.Column title="Fecha de alta" dataIndex="fechaAlta" key="fechaAlta" />
              <Table.Column
                title="Certificado de antiguedad"
                dataIndex="certificadoAntiguedad"
                key="certificadoAntiguedad"
              />
              <Table.Column
                title="Carta de reconocimiento de antiguedad"
                dataIndex="cartaReconocimientoAntiguedad"
                key="cartaReconocimientoAntiguedad"
              />
              <Table.Column
                title="Acta de nacimiento"
                dataIndex="actaNacimiento"
                key="actaNacimiento"
              />
              <Table.Column title="Aseguradora" dataIndex="aseguradora" key="aseguradora" />
              <Table.Column title="" dataIndex="borrar" key="borrar" />
            </Table>
          )}

          <ErrorList errorList={errorList} />

          <Grid container spacing={2} mt={2} justifyContent="start" alignItems="center">
            <Grid item xs={2} sm={2}>
              <IconButton
                aria-label="help"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon sx={{ fontSize: "1.5rem", color: "#FFC805" }} />
              </IconButton>

              {/* POP-UP DE AYUDA */}
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openInfo}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Paper sx={{ p: 1, bgcolor: "#FFC805" }} elevation={1}>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ¿Necesitas ayuda?
                  </CustomTypography>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhoneOutlinedIcon sx={{ fontSize: "1rem", mr: 1 }} />
                    800 800 1107
                    <EmailOutlinedIcon sx={{ fontSize: "1rem", mr: 1, ml: 2 }} />
                    avisosgmm@inter.mx
                  </CustomTypography>
                </Paper>
              </Popover>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={4} sm={4}>
              <CustomButton
                variant="contained"
                color="primary"
                component="label"
                size="small"
                sx={{
                  padding: "0.4rem",
                  width: "30%",
                  position: "relative",
                  left: "50%",
                  //transform: "translateX(-50%)",
                  marginTop: "-2rem",
                }}
                onClick={handleRetry}
              >
                <CustomTypography
                  variant="button"
                  sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                >
                  Reintentar
                </CustomTypography>
              </CustomButton>
            </Grid>
            <Grid item xs={4} sm={4}>
              <CustomButton
                disabled={sendDisabled}
                variant="contained"
                color="primary"
                component="label"
                size="small"
                sx={{
                  padding: "0.4rem",
                  width: "30%",
                  position: "relative",
                  left: "25%",
                  //transform: "translateX(-50%)",
                  marginTop: "-2rem",
                }}
                onClick={handleSendRequest}
              >
                <CustomTypography
                  variant="button"
                  sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                >
                  Enviar
                </CustomTypography>
              </CustomButton>
              <MassivePopUpForm open={open} />
              <MassivePopUpFormEdit open={openEdit} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default MassiveDynamicTable;
