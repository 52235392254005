import React, { useState, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import CustomBox from "../../../components/CustomBox";
import Grid from "@mui/material/Grid";
import LogoBox from "../LogoBox";
import Card from "@mui/material/Card";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import Footer from "../../../structures/Footer";
import Swal from "sweetalert2";

function MFASetup() {
  const [phoneNumber, setPhoneNumber] = useState("+52");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");

  useEffect(() => {
    // Initialize reCAPTCHA upon component mount
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      // eslint-disable-next-line no-unused-vars
      callback: function (response) {
        // reCAPTCHA solved - this callback will be called
      },
    });
  }, []);

  const handleSendVerificationCode = async (e) => {
    e.preventDefault();

    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      multiFactor(user)
        .getSession()
        .then(async function (multiFactorSession) {
          // Specify the phone number and pass the MFA session.
          const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession,
          };

          const phoneAuthProvider = new PhoneAuthProvider(auth);

          // Send SMS verification code.
          setVerificationId(
            await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier)
          );
        });
      // Let the user know the code has been sent
      Swal.fire({
        icon: "success",
        title: "Código Enviado",
        text: "Se ha enviado el código correctamente",
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error, intenta de nuevo",
      });
      console.log(error.message);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(user).enroll(multiFactorAssertion, "phoneNumber");
      Swal.fire({
        icon: "success",
        title: "MFA Configurado",
        text: "Se ha configurado correctamente un segundo método de autenticación",
      }).then(() => {
        window.location = "/";
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error, intenta de nuevo",
      });
      console.log(error.message);
    }
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    let formattedValue = inputValue.replace(/\D/g, "");
    formattedValue = formattedValue.startsWith("52")
      ? "+" + formattedValue
      : "+52" + formattedValue;
    formattedValue = formattedValue.slice(0, 13);
    if (formattedValue.startsWith("+52") && formattedValue.length < 4) {
      formattedValue = "";
    }
    setPhoneNumber(formattedValue);
  };

  return (
    <div>
      <>
        <CustomBox
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundColor: "#039ECC",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <CustomBox
          px={1}
          width="100%"
          height="100vh"
          mx="auto"
          position="absolute"
          zIndex={2}
          top="-4rem"
        >
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <LogoBox />
              <Card>
                {!verificationId ? (
                  <form onSubmit={handleSendVerificationCode}>
                    <CustomBox pt={4} pb={3} px={3}>
                      <CustomBox mb={2}>
                        <CustomInput
                          type="phone"
                          label="Número de Teléfono"
                          fullWidth
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          placeholder="+525555555555"
                        />
                      </CustomBox>

                      <CustomBox mt={4} mb={1}>
                        <CustomButton type="submit" variant="gradient" color="info" fullWidth>
                          Enviar código de verificación
                        </CustomButton>
                      </CustomBox>
                    </CustomBox>
                  </form>
                ) : (
                  <form onSubmit={handleVerifyCode}>
                    <CustomBox pt={4} pb={3} px={3}>
                      <CustomBox mb={2}>
                        <CustomInput
                          type="text"
                          label="Código de verificación"
                          fullWidth
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                        />
                      </CustomBox>

                      <CustomBox mt={4} mb={1}>
                        <CustomButton type="submit" variant="gradient" color="info" fullWidth>
                          Verificar
                        </CustomButton>
                      </CustomBox>
                    </CustomBox>
                  </form>
                )}
              </Card>
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
          <Footer light />
        </CustomBox>
      </>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export default MFASetup;
