import React from "react";
import { Steps } from "antd";
import { useStep } from "./StepContext";

const RequestProgress = () => {
  const step = useStep();
  return (
    <div
      style={{
        width: "40%",
        contentAlign: "left",
        marginLeft: "8px",
        marginRight: "auto",
      }}
    >
      <Steps
        type="navigation"
        size="small"
        current={step}
        className="form-navigation-steps"
        style={{
          color: "#7b809a",
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: "Montserrat",
        }}
        items={[
          {
            title: "Nueva solicitud",
          },
          {
            title: "Detalle de asegurados",
          },
        ]}
      />
    </div>
  );
};

export default RequestProgress;
