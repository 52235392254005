import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import CustomBox from "components/CustomBox";
import Footer from "structures/Footer";
import PolicyData from "./sections/PolicyData";
import CustomNavbar from "../../structures/CustomNavbar";

function Dashboard() {
  const [cardHeight, setCardHeight] = useState("90vh");

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const cardHeight = windowHeight - navbarHeight - footerHeight - 96;
      setCardHeight(`${cardHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <CustomNavbar />

      <Card
        sx={{
          p: 1,
          mt: { xs: 0, sm: 2, md: 4, lg: 8 },
          mb: 4,
          height: cardHeight,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.9),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden", // Add overflow: hidden to prevent the Card component from having a scroll
        }}
      >
        <PolicyData />
      </Card>

      <CustomBox id="footer" width="100%" position="relative" zIndex={2} bottom="1.625rem">
        <Footer />
      </CustomBox>
    </>
  );
}

export default Dashboard;
