import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AllInboxOutlinedIcon from "@mui/icons-material/AllInboxOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import SignIn from "pages/Authentication/SignIn";
import RequestSection from "pages/RequestSection";
import MassiveRequest from "pages/Massive_Request";
import RequestHistory from "pages/RequestHistory";

const routes = [
  {
    name: "Nueva solicitud",
    icon: <PostAddOutlinedIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Crear Solicitud",
        collapse: [
          {
            name: "Solicitud Individual",
            route: "/solicitud-individual",
            component: <RequestSection />,
          },
          {
            name: "Solicitud Masiva",
            route: "/solicitud-masiva",
            component: <MassiveRequest />,
          },
        ],
      },
    ],
  },
  {
    name: "Solicitudes",
    icon: <AllInboxOutlinedIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Consultar Solicitudes",
        collapse: [
          {
            name: "Consultar Solicitud",
            route: "/consultar-solicitud",
            component: <RequestHistory />,
          },
        ],
      },
    ],
  },
  {
    name: "Notificaciones",
    icon: <NotificationsNoneOutlinedIcon />,
    route: "/",
    component: <SignIn />,
  },
];

export default routes;
