import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useData, useDataUpdate } from "../../RequestSection/sections/DataContext";
import ItemCounter from "pages/RequestSection/sections/ItemCounter";
import StepProgress from "pages/RequestSummary/sections/StepProgress";
import { Backdrop, Grid, IconButton, Paper, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import CustomTypography from "components/CustomTypography";
import CustomButton from "components/CustomButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDialog } from "pages/RequestSection/sections/DialogContext";
import { useDialogUpdate } from "pages/RequestSection/sections/DialogContext";
import PopUpForm from "pages/RequestSection/sections/PopUpForm";
import Swal from "sweetalert2";
import RequestProgress from "pages/RequestSection/sections/RequestProgress";
import axios from "axios";
import { auth } from "context/AuthContext";
import "./styles.css";
import { useIndexUpdate } from "../../RequestSection/sections/IndexContext";
import cloneDeep from "lodash/cloneDeep";
import CircularProgress from "@mui/material/CircularProgress";
import { useUser } from "context/UserContext";
import CustomBox from "components/CustomBox";

const DynamicTable = () => {
  const data = useData();
  const updateData = useDataUpdate();
  const open = useDialog();
  const toggleDialog = useDialogUpdate();
  const editIndex = useIndexUpdate();
  const user = useUser();
  const clientId = user.clientID;
  const [aseguradora, setAseguradora] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const [tableData, setTableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [backdropHeight, setBackdropHeight] = useState("90vh");
  const [boxHeight, setBoxHeight] = useState("90vh");

  const [sendDisabled, setSendDisabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const backdropHeight = windowHeight - navbarHeight - footerHeight - 160;
      const boxHeight = windowHeight - navbarHeight - footerHeight - 175;
      setBackdropHeight(`${backdropHeight}px`);
      setBoxHeight(`${boxHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Enable or disable button if all rows that require files have files uploaded
  useEffect(() => {
    if (tableData.length === 0) {
      setSendDisabled(true);
      return;
    }
    const altaConReconocimientoAntiguedad = tableData.filter((item) => item.tipoSolicitudId === 1);
    const altaRecienNacido = tableData.filter((item) => item.tipoSolicitudId === 3);

    if (altaConReconocimientoAntiguedad.length > 0) {
      const missingCertificado = altaConReconocimientoAntiguedad.some(
        (item) => item.asegurados[0].certificadoFileName === ""
      );

      const missingCarta = altaConReconocimientoAntiguedad.some(
        (item) => item.asegurados[0].cartaFileName === ""
      );

      if (missingCertificado || missingCarta) {
        setSendDisabled(true);
      } else {
        setSendDisabled(false);
      }
    } else if (altaRecienNacido.length > 0) {
      const missingActaNacimiento = altaRecienNacido.some(
        (item) => item.asegurados[0].actaNacimientoFileName === ""
      );

      if (missingActaNacimiento) {
        setSendDisabled(true);
      } else {
        setSendDisabled(false);
      }
    } else {
      setSendDisabled(false);
    }
  }, [tableData]);

  useEffect(() => {
    const newData = data.map((item, index) => {
      return {
        ...item,
        key: index.toString(),
      };
    });
    setTableData(newData);
  }, [data]);

  const getRelacionText = (relacionId) => {
    switch (relacionId) {
      case 1:
        return "Titular";
      case 2:
        return "Cónyuge";
      case 3:
        return "Hijo";
      default:
        return "";
    }
  };

  const getSexoText = (sexoId) => {
    return sexoId === 1 ? "Femenino" : "Masculino";
  };

  const updateTableData = (key, property, value) => {
    setTableData((oldValues) => {
      const index = oldValues.findIndex((item) => item.key === key);
      const updatedData = [...oldValues];
      updatedData[index] = {
        ...updatedData[index],
        [property]: value,
      };
      // if data in table changes, update data in localData array (state)
      // prevents data from resetting when editing a row
      updateData(updatedData[index], true, index);

      return updatedData;
    });
  };

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

  useEffect(() => {
    const fetchPolizaValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/razones-sociales/" + clientId + "/polizas", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setAseguradora(response.data[0].aseguradora.nombre);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchPolizaValues();
  }, [clientId]);

  const sendData = async (dataToSend) => {
    setIsLoading(true);
    const userToken = await auth.currentUser.getIdToken(true);
    const actualDataToSend = cloneDeep(dataToSend[0]);
    if (dataToSend.length > 1) {
      for (let i = 1; i < dataToSend.length; i++) {
        actualDataToSend.asegurados.push(cloneDeep(dataToSend[i].asegurados[0]));
      }
    }

    try {
      const response = await axios.post(
        url + "/solicitudes",
        JSON.stringify(actualDataToSend), // Convert data to JSON string
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      );
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleSendRequest = async () => {
    // show error message if file is not uploaded for fields that require files
    // this should apply to all rows that require files

    const result = await Swal.fire({
      title: "¿Estás seguro que la información es la correcta?",
      showCancelButton: true,
      cancelButtonText: "Corregir",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#73B87E",
      cancelButtonColor: "#CACACA",
      reverseButtons: true,
      customClass: {
        container: "my-swal",
        confirmButton: "my-swal-confirm-button",
        cancelButton: "my-swal-cancel-button",
        actions: "my-swal-actions",
      },
    });
    if (result.isConfirmed) {
      try {
        // before sending, create a new variable with same data but remove certificadoFileName and cartaFileName from table data to avoid errors
        const tableDataCopy = cloneDeep(tableData);
        tableDataCopy.forEach((item) => {
          item.asegurados.forEach((asegurado) => {
            delete asegurado.cartaFileName;
            delete asegurado.certificadoFileName;
            delete asegurado.actaNacimientoFileName;
            delete asegurado.certificadoFileURL;
            delete asegurado.cartaFileURL;
            delete asegurado.actaNacimientoFileURL;
          });
          delete item.actaNacimientoFileName;
          delete item.certificadoFileName;
          delete item.cartaFileName;
          delete item.key;
        });

        const serverResponse = await sendData(tableDataCopy);
        if (serverResponse.status === 201) {
          Swal.fire({
            title: "¡Solicitud enviada!",
            text: "Tu solicitud ha sido enviada con éxito.",
            icon: "success",
            confirmButtonColor: "#73B87E",
            timer: 300000,
          }).then(() => {
            window.location = "/resumen-solicitud?id=" + serverResponse.data.id;
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `Hubo un error al enviar la solicitud.`,
            icon: "error",
            confirmButtonColor: "#73B87E",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Hubo un error al enviar la solicitud. Por favor, reintenta.",
          icon: "error",
          confirmButtonColor: "#73B87E",
        });
      }
    }
  };

  const handleAddMore = () => {
    editIndex(-1);
    toggleDialog();
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openInfo = Boolean(anchorEl);

  //delete row by index from table and from localData array (state) using filter method

  const deleteByIndex = (index) => {
    updateData(data.filter((_, row) => row !== index));
  };

  const columns = [
    {
      title: "",
      key: "editar",
      align: "center",
      render: (_, record, index) => (
        <Space size="middle">
          <i
            className="fas fa-edit"
            style={{
              color: "#73B87E",
              cursor: "pointer",
            }}
            onClick={(e) => {
              editIndex(index);
              toggleDialog();
            }}
          ></i>
        </Space>
      ),
    },
    {
      title: "Tipo de O.T.",
      dataIndex: "tipoSolicitudId",
      key: "tipoSolicitudId",
      align: "center",
      render: (tipoSolicitudId) => {
        switch (tipoSolicitudId) {
          case 1:
            return "Alta con reconocimiento de antigüedad";
          case 2:
            return "Alta sin reconocimiento de antigüedad";
          case 3:
            return "Alta de recién nacido";
          case 4:
            return "Baja de asegurado";
          default:
            return null;
        }
      },
    },
    {
      title: "Tipo de endoso",
      dataIndex: "motivoEndosoId",
      key: "motivoEndosoId",
      align: "center",
      render: (motivoEndosoId) => {
        switch (motivoEndosoId) {
          case 1:
            return "Alta";
          case 2:
            return "Baja";
          default:
            return null;
        }
      },
    },
    {
      title: "No. Certificado",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados ? asegurados.map((asegurado) => asegurado.numCertificado).join() : ""}
        </span>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>{asegurados ? asegurados.map((asegurado) => asegurado.nombre).join() : ""}</span>
      ),
    },
    {
      title: "Apellido Paterno",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados ? asegurados.map((asegurado) => asegurado.apellidoPaterno).join() : ""}
        </span>
      ),
    },
    {
      title: "Apellido Materno",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados ? asegurados.map((asegurado) => asegurado.apellidoMaterno).join() : ""}
        </span>
      ),
    },
    {
      title: "Parentesco",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados
            ? asegurados.map((asegurado) => getRelacionText(asegurado.relacionId)).join()
            : ""}
        </span>
      ),
    },
    {
      title: "Sexo",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados ? asegurados.map((asegurado) => getSexoText(asegurado.sexoId)).join() : ""}
        </span>
      ),
    },
    {
      title: "Fecha de Nacimiento",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados) => (
        <span>
          {asegurados ? asegurados.map((asegurado) => asegurado.fechaNacimiento).join() : ""}
        </span>
      ),
    },
    {
      title: "Fecha de alta",
      dataIndex: "fechaAlta",
      key: "fechaAlta",
      align: "center",
      render: (fechaAlta, record) => {
        if (record.tipoSolicitudId === 4 || record.tipoSolicitudId === 5) {
          return <span>No aplica</span>;
        } else {
          return <span>{fechaAlta}</span>;
        }
      },
    },
    {
      title: "Fecha de baja",
      dataIndex: "fechaBaja",
      key: "fechaBaja",
      align: "center",
      render: (fechaBaja, record) => {
        if (
          record.tipoSolicitudId === 1 ||
          record.tipoSolicitudId === 2 ||
          record.tipoSolicitudId === 3
        ) {
          return <span>No aplica</span>;
        } else {
          return <span>{fechaBaja}</span>;
        }
      },
    },
    {
      title: "Fecha de carta",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados, record) => {
        if (record.tipoSolicitudId !== 1) {
          return <span>No aplica</span>;
        }
        return (
          <span>
            {asegurados
              ? asegurados.map((asegurado) => asegurado.fechaCartaReconocimiento).join()
              : ""}
          </span>
        );
      },
    },
    {
      title: "Certificado de antiguedad",
      dataIndex: ["asegurados", 0, "certificadoFileName"],
      key: ["asegurados", 0, "certificadoFileName"],
      align: "center",
      render: (certificadoFileName, record) => {
        if (record.tipoSolicitudId === 1) {
          let fileURL = null;
          if (record.asegurados[0].certificadoFile) {
            const byteCharacters = atob(record.asegurados[0].certificadoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {certificadoFileName === "" ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update certificadoFileName and certificadoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].certificadoFileName = file.name;
                          newAsegurados[0].certificadoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {certificadoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].certificadoFileName = "";
                      newAsegurados[0].certificadoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                        cursor: "pointer",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Carta de reconocimiento de antiguedad",
      dataIndex: ["asegurados", 0, "cartaFileName"],
      key: ["asegurados", 0, "cartaFileName"],
      align: "center",
      render: (cartaReconocimientoFileName, record) => {
        if (record.tipoSolicitudId === 1) {
          let fileURL = null;
          if (record.asegurados[0].cartaReconocimientoFile) {
            const byteCharacters = atob(record.asegurados[0].cartaReconocimientoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {cartaReconocimientoFileName === "" ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update cartaReconocimientoFileName and cartaReconocimientoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].cartaFileName = file.name;
                          newAsegurados[0].cartaReconocimientoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {cartaReconocimientoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].cartaFileName = "";
                      newAsegurados[0].cartaReconocimientoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Acta de nacimiento",
      dataIndex: ["asegurados", 0, "actaNacimientoFileName"],
      key: ["asegurados", 0, "actaNacimientoFileName"],
      align: "center",
      render: (actaNacimientoFileName, record) => {
        if (record.tipoSolicitudId === 3) {
          let fileURL = null;
          if (record.asegurados[0].actaNacimientoFile) {
            const byteCharacters = atob(record.asegurados[0].actaNacimientoFile.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });
            fileURL = URL.createObjectURL(blob);
          }
          return (
            <>
              {actaNacimientoFileName === "" ? (
                <CustomButton
                  variant="button"
                  sx={{
                    backgroundColor: "#81CFE6",
                    color: "#fff",
                    width: "16vh",
                    borderRadius: "2rem",
                  }}
                  size="small"
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = ".pdf";
                    input.onchange = (event) => {
                      const file = event.target.files[0];
                      if (file.type !== "application/pdf") {
                        Swal.fire({
                          title: "¡Error!",
                          text: "Por favor, selecciona un archivo PDF.",
                          toast: true,
                          position: "top-end",
                          timer: 6000,
                          icon: "error",
                          color: "#fff",
                          background: "#EA5151",
                          timerProgressBar: true,
                          showConfirmButton: false,
                          customClass: {
                            container: "my-swal",
                          },
                          onOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                          },
                        });
                        return;
                      }
                      if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          // update actaNacimientoFileName and actaNacimientoFile values in table
                          const newAsegurados = [...record.asegurados];
                          newAsegurados[0].actaNacimientoFileName = file.name;
                          newAsegurados[0].actaNacimientoFile = reader.result;
                          updateTableData(record.key, "asegurados", newAsegurados);
                        };
                      }
                    };
                    input.click();
                  }}
                >
                  Subir archivo
                </CustomButton>
              ) : (
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <a
                    href={fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "8px" }}
                  >
                    {actaNacimientoFileName}
                  </a>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      // remove file from table data and clear states
                      const newAsegurados = [...record.asegurados];
                      newAsegurados[0].actaNacimientoFileName = "";
                      newAsegurados[0].actaNacimientoFile = "";
                      updateTableData(record.key, "asegurados", newAsegurados);
                    }}
                  >
                    <i
                      className="fas fa-trash-alt"
                      style={{
                        color: "#C72222",
                        scale: "0.7",
                      }}
                    ></i>
                  </IconButton>
                </div>
              )}
            </>
          );
        } else {
          return <span>No aplica</span>;
        }
      },
    },
    {
      title: "Aseguradora",
      dataIndex: "aseguradora",
      key: "aseguradora",
      align: "center",
      render: (asegurado) => {
        return <span>{aseguradora}</span>;
      },
    },
    {
      title: "",
      key: "borrar",
      render: (_, record, index) => (
        <Space size="middle">
          <i
            className="fas fa-trash-alt"
            style={{
              color: "#C72222",
              cursor: "pointer",
            }}
            //delete row by index from table and from localData array (state) using filter method
            onClick={() => {
              Swal.fire({
                title: "¿Está seguro que desea eliminar el registro?",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#E62530",
                cancelButtonColor: "#CACACA",
                customClass: {
                  container: "my-swal",
                  confirmButton: "my-swal-confirm-button",
                  cancelButton: "my-swal-cancel-button",
                  actions: "my-swal-actions",
                },
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteByIndex(index);
                }
              });
            }}
          ></i>
        </Space>
      ),
    },
  ];

  return (
    <>
      {isLoading === true ? (
        <>
          <CustomBox sx={{ height: boxHeight }} />
          <Backdrop
            sx={{
              height: backdropHeight,
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                marginTop: "-50px",
                marginLeft: "-50px",
              }}
            />
          </Backdrop>
        </>
      ) : (
        <>
          <RequestProgress />
          <ItemCounter />
          <StepProgress />
          <Table
            dataSource={tableData}
            columns={columns}
            style={{ width: "95%", marginTop: "0rem", overflowX: "auto" }}
            deleteRow={deleteByIndex}
          >
            <Table.Column
              title="Editar"
              dataIndex="editar"
              key="editar"
              style={{ headerBg: "#fff" }}
            />
            <Table.Column title="Tipo de O.T." dataIndex="tipoSolicitudId" key="tipoSolicitudId" />
            <Table.Column title="Tipo de endoso" dataIndex="motivoEndosoId" key="motivoEndosoId" />
            <Table.Column
              title="No. Certificado"
              dataIndex="numCertifiicado"
              key="numCertifiicado"
            />
            <Table.Column title="Nombre" dataIndex="nombre" key="nombre" />
            <Table.Column
              title="Apellido Paterno"
              dataIndex="apellidoPaterno"
              key="apellidoPaterno"
            />
            <Table.Column
              title="Apellido Materno"
              dataIndex="apellidoMaterno"
              key="apellidoMaterno"
            />
            <Table.Column title="Relacion" dataIndex="relacionId" key="relacionId" />
            <Table.Column title="Sexo" dataIndex="sexoId" key="sexoId" />
            <Table.Column
              title="Fecha de Nacimiento"
              dataIndex="fechaNacimiento"
              key="fechaNacimiento"
            />
            <Table.Column title="Fecha de alta" dataIndex="fechaAlta" key="fechaAlta" />
            <Table.Column
              title="Certificado de antiguedad"
              dataIndex="certificadoAntiguedad"
              key="certificadoAntiguedad"
            />
            <Table.Column
              title="Carta de reconocimiento de antiguedad"
              dataIndex="cartaReconocimientoAntiguedad"
              key="cartaReconocimientoAntiguedad"
            />
            <Table.Column
              title="Acta de nacimiento"
              dataIndex="actaNacimiento"
              key="actaNacimiento"
            />
            <Table.Column title="Aseguradora" dataIndex="aseguradora" key="aseguradora" />
            <Table.Column title="Borrar" dataIndex="borrar" key="borrar" />
          </Table>

          <Grid container spacing={2} mt={2} justifyContent="start" alignItems="center">
            <Grid item xs={4} sm={4}>
              <IconButton
                aria-label="help"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon sx={{ fontSize: "1.5rem", color: "#FFC805" }} />
              </IconButton>

              {/* POP-UP DE AYUDA */}
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openInfo}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Paper sx={{ p: 1, bgcolor: "#FFC805" }} elevation={1}>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ¿Necesitas ayuda?
                  </CustomTypography>
                  <CustomTypography
                    sx={{
                      p: 1,
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPhoneOutlinedIcon sx={{ fontSize: "1rem", mr: 1 }} />
                    800 800 1107
                    <EmailOutlinedIcon sx={{ fontSize: "1rem", mr: 1, ml: 2 }} />
                    avisosgmm@inter.mx
                  </CustomTypography>
                </Paper>
              </Popover>
            </Grid>
            <Grid item xs={4} sm={6}>
              <CustomButton
                disabled={sendDisabled}
                variant="contained"
                color="primary"
                component="label"
                size="small"
                sx={{
                  padding: "0.4rem",
                  width: "20%",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "-2rem",
                }}
                onClick={handleSendRequest}
              >
                <CustomTypography
                  variant="button"
                  sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                >
                  Enviar
                </CustomTypography>
              </CustomButton>
              <PopUpForm open={open} />
            </Grid>
            <Grid item xs={4} sm={2} justifyContent="end">
              <IconButton aria-label="add" onClick={handleAddMore}>
                <CustomTypography sx={{ fontSize: "6vh", fontWeight: "bold" }}>
                  <AddCircleIcon sx={{ color: "#73B87E" }} />
                </CustomTypography>
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DynamicTable;
