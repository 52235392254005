import React, { useEffect, useState } from "react";
import { auth } from "../../../context/AuthContext";
import axios from "axios";
import PropTypes from "prop-types";
import CustomTypography from "../../../components/CustomTypography";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
import CustomBox from "components/CustomBox";
import { CircularProgress } from "@mui/material";

const History = ({ id }) => {
  const url = process.env.REACT_APP_NOTIFICATION_ENDPOINTS_URL;
  const [historial, setHistorial] = useState([]);
  const [data, setData] = useState([]);
  const [statusText, setStatusText] = useState("");
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);

  useEffect(() => {
    setIsHistoryLoading(true);
    async function fetchHistory() {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/solicitud/" + id + "/notificaciones", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
      setHistorial(result);
      setIsHistoryLoading(false);
    }
    fetchHistory();
  }, []);

  useEffect(() => {
    if (historial.data && historial.data.length > 0) {
      setStatusText(historial.statusText);
      for (let i = 0; i < historial.data.length; i++) {
        // Get fecha from historial
        const fecha = historial.data[i].fecha;
        // Convert fecha to Date object and subtract 6 hours from it
        const newDate = new Date(fecha);
        newDate.setHours(newDate.getHours() - 6);
        //separate date and time from newDate
        const newDateFormatted = newDate.toString().split(" ");
        const newTime = newDateFormatted[4];
        const newDay = newDateFormatted[2];
        const newMonth = newDateFormatted[1];
        const newYear = newDateFormatted[3];
        const newDateFormatted2 = newDay + "/" + newMonth + "/" + newYear;

        setData([
          ...data,
          {
            key: i,
            date: newDateFormatted2,
            time: newTime,
            status: historial.data[i].solicitud.status.id,
            content: historial.data[i].contenido,
          },
        ]);
      }
    }
  }, [historial]);

  const columns = [
    {
      dataIndex: "date",
      key: "date",
      render: (date, record, index) => {
        const isNewest = index === data.length - 1;
        return (
          <>
            <CustomTypography
              sx={{
                color: isNewest ? "#FFD100" : "#ADADAD",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              <CalendarOutlined /> {date}
            </CustomTypography>
          </>
        );
      },
    },

    {
      dataIndex: "time",
      key: "time",
      render: (time, record, index) => {
        const isNewest = index === data.length - 1;
        return (
          <>
            <CustomTypography
              sx={{
                color: isNewest ? "#FFD100" : "#ADADAD",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              <ClockCircleOutlined /> {time}
            </CustomTypography>
          </>
        );
      },
    },
    {
      dataIndex: "status",
      key: "status",
      render: (status, record, index) => {
        const isNewest = index === data.length - 1;
        let text = "";
        switch (status) {
          case 1:
            text = "Solicitud generada al CES";
            break;
          case 2:
            text = "Solicitud recibida por el CES";
            break;
          case 3:
            text = "Solicitud en revisión por el CES";
            break;
          case 4:
            text = "Solicitud rechazada por el CES";
            break;
          case 5:
            text = "Solicitud cancelada por el CES";
            break;
          case 6:
            text = "Solicitud aprobada por el CES";
            break;
          default:
            text = "Sin comentarios...";
            break;
        }
        return (
          <CustomTypography
            sx={{
              color: isNewest ? "#FFD100" : "#ADADAD",
              fontWeight: "bold",
              fontSize: "1.1rem",
            }}
          >
            {text}
          </CustomTypography>
        );
      },
    },
  ];

  return (
    <>
      {isHistoryLoading ? (
        <CircularProgress
          sx={{
            marginLeft: "-20px",
            marginTop: "40px",
          }}
        />
      ) : (
        <>
          <CustomBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              width: "90%",
              height: "100%",
              mb: 8,
              mt: 8,
            }}
          >
            <CustomTypography
              fontWeight="bold"
              sx={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                lineHeight: 1.375,
                color: "error.main",
              }}
            >
              {statusText}
            </CustomTypography>
            <CustomTypography
              fontWeight="bold"
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                lineHeight: 1.375,
                marginLeft: "2rem",
                color: "#ADADAD",
                marginTop: "2rem",
              }}
            >
              Historial
            </CustomTypography>
            <Table
              style={{ width: "100%", marginTop: "2rem" }}
              columns={columns}
              dataSource={data.slice().reverse()} // Show the most recent notification first
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <CustomTypography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      lineHeight: 1.375,
                      textAlign: "center",
                      color: "#000",
                    }}
                  >
                    {record.content}
                  </CustomTypography>
                ),
                rowExpandable: (record) => record.name !== "Not Expandable",
              }}
            />
          </CustomBox>
        </>
      )}
    </>
  );
};

export default History;

History.propTypes = {
  id: PropTypes.string,
};
