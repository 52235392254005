import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import PropTypes from "prop-types";
import CustomBox from "components/CustomBox";
import Footer from "structures/Footer";
import CustomNavbar from "../../structures/CustomNavbar";
import RequestStatus from "./sections/RequestStatus";
import { useLocation } from "react-router-dom";
import StepProgress from "./sections/StepProgress";
import CustomButton from "components/CustomButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import History from "pages/RequestHistory/sections/History";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Summary = ({ id: propId }) => {
  const query = useQuery();
  const id = propId || query.get("id");
  const [idList, setIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [backdropHeight, setBackdropHeight] = useState("90vh");
  const [boxHeight, setBoxHeight] = useState("90vh");
  const [reqCounter, setReqCounter] = useState([]);
  const [timeEstimate, setTimeEstimate] = useState(0);

  const handleReturn = (value) => {
    setTimeEstimate(value.poliza.razonSocial.cliente.tiempoEstimadoAplicacion);
    reqCounter.push(value);
  };

  useEffect(() => {
    const handleLoader = () => {
      if (reqCounter.length === idList.length) {
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
    };
    handleLoader();
  }, [reqCounter, idList]);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : -60;
      const footerHeight = footerElement ? footerElement.offsetHeight : -60;
      const backdropHeight = windowHeight - navbarHeight - footerHeight - 160;
      const boxHeight = windowHeight - navbarHeight - footerHeight - 175;
      setBackdropHeight(`${backdropHeight}px`);
      setBoxHeight(`${boxHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const countRequests = (ids) => {
      // split the id string into an array and count the number of elements
      const count = ids.split(",").length;
      for (let i = 0; i < count; i++) {
        idList.push(ids.split(",")[i]);
      }
    };
    countRequests(id);
  }, [id]);

  return (
    <>
      {isLoading === true ? (
        <>
          <CustomBox sx={{ height: boxHeight }} />
          <Backdrop
            sx={{
              height: backdropHeight,
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                marginTop: "-50px",
                marginLeft: "-50px",
              }}
            />
          </Backdrop>
        </>
      ) : (
        <>
          <CustomNavbar />

          <CustomBox
            sx={{
              p: 1,
              mt: { xs: 4, md: 4 },
              mb: 4,
              minHeight: "fill-available", // Use minHeight instead of height
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                rgba(white.main, 0.9),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <CustomBox width="90%" mx="auto" position="relative" top="10rem">
              <StepProgress />
            </CustomBox>

            {idList.map((id) => (
              <RequestStatus key={id} id={id} onReturn={handleReturn} />
            ))}

            <Grid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ mt: 16, mb: 8 }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                <CustomButton
                  variant="contained"
                  color="primary"
                  width="20%"
                  onClick={() => (window.location.href = "/opciones-solicitud")}
                >
                  Nueva Solicitud
                </CustomButton>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <CustomButton
                  variant="contained"
                  color="primary"
                  width="20%"
                  onClick={() => (window.location.href = "/consultar-solicitud")}
                >
                  Solicitudes
                </CustomButton>
              </Grid>
            </Grid>

            <History id={idList[0]} />
          </CustomBox>
        </>
      )}

      <CustomBox width="100%" position="relative" zIndex={2} bottom="1.625rem">
        <Footer />
      </CustomBox>
    </>
  );
};

export default Summary;

Summary.propTypes = {
  id: PropTypes.string,
};
