import React, { useEffect, useState } from "react";
import {
  AppBar,
  Badge,
  Grid,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { auth } from "../../../context/AuthContext";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CustomTypography from "components/CustomTypography";
import CustomButton from "components/CustomButton";
import { useUser } from "../../../context/UserContext";

const NavMenu = () => {
  const generateSignedURLEndpoint = process.env.REACT_APP_GENERATE_SIGNED_URL_FUNCTION;
  // State to track download status
  const [isDownloading, setIsDownloading] = useState(false);
  const [notificaciones, setNotificaciones] = useState([]);
  const user = useUser();
  const url = process.env.REACT_APP_NOTIFICATION_ENDPOINTS_URL;

  useEffect(() => {
    let clienteId = user?.user?.cliente?.id;
    if (clienteId !== undefined) {
      const fetchNotificaciones = async () => {
        const userToken = await auth.currentUser.getIdToken(true);
        const result = await axios
          .get(url + "/cliente/" + clienteId + "/notificaciones", {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          })
          .then((response) => {
            setNotificaciones(response.data.filter((record) => !record.leido));
          })
          .catch((error) => {
            console.log(error);
          });
      };
      fetchNotificaciones();
    }
  }, [user]);

  //download policy structure
  const downloadPolicyStructure = async () => {
    setIsDownloading(true); // Set downloading state to true
    try {
      const userToken = await auth.currentUser.getIdToken(true);
      const policyStructurePath = "estructuras_poliza/" + user.user.cliente.estructuraPoliza;
      const endpoint = generateSignedURLEndpoint + "?file=" + policyStructurePath;

      const { data } = await axios.get(endpoint, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      });

      // Assuming the server returns a JSON with url field
      const fileURL = data.url;

      // Force download the file
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", ""); // Add a filename if needed
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setIsDownloading(false); // Reset downloading state after download, even if there's an error
    }
  };

  //download layout
  const downloadLayout = async () => {
    setIsDownloading(true); // Set downloading state to true
    try {
      const userToken = await auth.currentUser.getIdToken(true);
      const cargaMasivaLayoutPath = "layout_solicitud_masiva/PortalLigero_escriturasv.xlsx";
      const endpoint = generateSignedURLEndpoint + "?file=" + cargaMasivaLayoutPath;

      const { data } = await axios.get(endpoint, {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      });

      // Assuming the server returns a JSON with url field
      const fileURL = data.url;

      // Force download the file
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", ""); // Add a filename if needed
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setIsDownloading(false); // Reset downloading state after download, even if there's an error
    }
  };

  const handleRequest = () => {
    window.location = "/opciones-solicitud";
  };

  const handleSearch = () => {
    window.location = "/consultar-solicitud";
  };

  const handleNotifications = () => {
    window.location = "/notificaciones";
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const drawerItems = [
    {
      text: "Nueva Solicitud",
      onClick: handleRequest,
      icon: (
        <CustomTypography
          sx={{
            color: "#039ECC",
            fontWeight: "bold",
            fontSize: { xs: "0.8rem", md: "1.1rem" },
            whiteSpace: "nowrap",
          }}
        >
          Nueva Solicitud
        </CustomTypography>
      ),
    },
    {
      text: "Solicitudes",
      onClick: handleSearch,
      icon: (
        <CustomTypography
          sx={{
            color: "#039ECC",
            fontWeight: "bold",
            fontSize: { xs: "0.8rem", md: "1.1rem" },
            whiteSpace: "nowrap",
          }}
        >
          Solicitudes
        </CustomTypography>
      ),
    },
    {
      text: "Estructura de Póliza",
      onClick: downloadPolicyStructure,
      icon: (
        <CustomTypography
          sx={{
            color: "#039ECC",
            fontWeight: "bold",
            fontSize: { xs: "0.8rem", md: "1.1rem" },
            whiteSpace: "nowrap",
          }}
        >
          Estructura de Póliza
        </CustomTypography>
      ),
    },
    {
      text: "Descargar Layout",
      onClick: downloadLayout,
      icon: (
        <CustomTypography
          sx={{
            color: "#039ECC",
            fontWeight: "bold",
            fontSize: { xs: "0.8rem", md: "1.1rem" },
            whiteSpace: "nowrap",
          }}
        >
          Descargar Layout
        </CustomTypography>
      ),
    },
    {
      text: "Notificaciones",
      onClick: handleNotifications,
      icon: <NotificationsNoneOutlinedIcon />,
    },
    {
      text: "Buscar",
      onClick: handleSearch,
      icon: <SearchOutlinedIcon />,
    },
  ];
  const isMdScreenOrBigger = useMediaQuery((theme) => theme.breakpoints.up(912));

  return (
    <AppBar
      style={{
        background: "#fff",
        height: "4rem",
        top: "6rem",
        left: "0rem",
      }}
    >
      <Toolbar>
        {isMdScreenOrBigger ? (
          <Grid
            item
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            sx={{ flexWrap: "wrap" }}
          >
            <Grid item xs={0} sm={0} md={2}>
              <CustomButton
                variant="text"
                onClick={handleRequest}
                color="transparent"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CustomTypography
                  sx={{
                    color: "#039ECC",
                    fontWeight: "bold",
                    fontSize: { sm: "0.8rem", md: "1.1rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Nueva Solicitud
                </CustomTypography>
              </CustomButton>
            </Grid>
            <Grid item xs={0} sm={0} md={2}>
              <CustomButton
                variant="text"
                onClick={handleSearch}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CustomTypography
                  sx={{
                    color: "#039ECC",
                    fontWeight: "bold",
                    fontSize: { sm: "0.8rem", md: "1.1rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Solicitudes{" "}
                </CustomTypography>
              </CustomButton>
            </Grid>

            <Grid item xs={0} sm={0} md={2}>
              <CustomButton
                variant="text"
                onClick={downloadPolicyStructure}
                color="transparent"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CustomTypography
                  sx={{
                    color: "#039ECC",
                    fontWeight: "bold",
                    fontSize: { sm: "0.8rem", md: "1.1rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Estructura de Póliza
                </CustomTypography>
              </CustomButton>
            </Grid>

            <Grid item xs={0} sm={0} md={2}>
              <CustomButton
                variant="text"
                onClick={downloadLayout}
                color="transparent"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CustomTypography
                  sx={{
                    color: "#039ECC",
                    fontWeight: "bold",
                    fontSize: { sm: "0.8rem", md: "1.1rem" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Descargar Layout
                </CustomTypography>
              </CustomButton>
            </Grid>

            <Grid item xs={0} sm={0} md={2}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={handleNotifications}
                  variant="contained"
                  sx={{
                    marginRight: "1.5rem",
                    backgroundColor: "transparent",
                    fontSize: "1.8rem",
                  }}
                >
                  <Badge badgeContent={notificaciones.length} color="error">
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  onClick={handleSearch}
                  variant="contained"
                  sx={{
                    marginRight: "1.5rem",
                    backgroundColor: "transparent",
                    fontSize: "1.8rem",
                  }}
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={0}
              sx={{
                marginLeft: "auto",
              }}
            >
              <MenuIcon
                onClick={toggleDrawer}
                sx={{
                  fontSize: "2rem",
                  color: "#039ECC",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={toggleDrawer}
              sx={{
                "& .MuiDrawer-paper": {
                  width: "50%",
                  maxWidth: "70%",
                  overflowX: "hidden",
                },
              }}
            >
              <List>
                {drawerItems.map((item, index) => {
                  const { text, onClick } = item;
                  return (
                    <ListItem
                      key={text}
                      onClick={onClick}
                      sx={{
                        mt: 2,
                        mb: 2,
                        ml: 2,
                        mr: 2,
                      }}
                    >
                      <ListItemText
                        primary={text}
                        sx={{
                          color: "#039ECC",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#F5F5F5",
                          },
                          fontSize: "3rem",
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavMenu;
