import React from "react";
import { Container, Grid, IconButton } from "@mui/material";
import CustomBox from "../../../components/CustomBox";
import CustomTypography from "../../../components/CustomTypography";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const RequestOptions = () => {
  const openSinglePopUpForm = () => {
    window.location = "/tabla-individual";
  };

  const openMassivePopUpForm = () => {
    window.location = "/tabla-masiva";
  };

  return (
    <>
      <CustomBox width="70%" height="80vh" mx="auto" position="relative" top="6rem">
        <Container>
          <Grid
            container
            spacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="space-evenly"
            alignItems="stretch"
            direction="column"
            paddingLeft={{ xs: 0, sm: 2, md: 20 }}
            paddingRight={{ xs: 0, sm: 2, md: 20 }}
          >
            <Grid item xs={12}>
              <IconButton
                sx={{
                  backgroundColor: "#73B87E",
                  borderRadius: "1rem",
                  padding: "1rem",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#73B860",
                  },
                }}
                onClick={openSinglePopUpForm}
              >
                <AccountCircleOutlinedIcon color="white" />
                <CustomTypography
                  variant="h6"
                  color="white"
                  sx={{
                    marginLeft: "1rem",
                  }}
                >
                  Solicitud Individual
                </CustomTypography>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <IconButton
                sx={{
                  backgroundColor: "#81CFE6",
                  borderRadius: "1rem",
                  padding: "1rem",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#81CFD6",
                  },
                }}
                onClick={openMassivePopUpForm}
              >
                <InsertDriveFileOutlinedIcon color="white" />
                <CustomTypography
                  variant="h6"
                  color="white"
                  sx={{
                    marginLeft: "1rem",
                  }}
                >
                  Solicitud Masiva
                </CustomTypography>
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </CustomBox>
    </>
  );
};

export default RequestOptions;
