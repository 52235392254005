export default function MassiveFormat(data) {
  const option = data.tipoSolicitudId;
  switch (option) {
    case "A": {
      const processedData = {
        tipoSolicitudId: data.tipoSolicitudId,
        motivoEndosoId: data.motivoEndosoId,
        polizaId: data.polizaId,
        inicioVigenciaEndoso: data.inicioVigenciaEndoso,
        observaciones: data.observaciones,
        fechaAlta: data.fechaAlta,
        numSolicitud: data.numSolicitud,
        actaNacimientoFileName: "No aplica",
        asegurados: [
          {
            nombre: data.nombre,
            apellidoPaterno: data.apellidoPaterno,
            apellidoMaterno: data.apellidoMaterno,
            relacionId: data.relacionId,
            sexoId: data.sexoId,
            fechaNacimiento: data.fechaNacimiento,
            cartaFileName: data.cartaFileName,
            certificadoFileName: data.certificadoFileName,
            certificadoFileURL: data.certificadoFileURL,
            cartaFileURL: data.cartaFileURL,
            cartaReconocimientoFile: data.cartaReconocimientoFile,
            certificadoFile: data.certificadoFile,
            fechaCartaReconocimiento: data.fechaCartaReconocimiento,
            numCertifiicado: data.numCertifiicado,
          },
        ],
      };
      return processedData;
    }
    case "B": {
      const processedData = {
        tipoSolicitudId: data.tipoSolicitudId,
        motivoEndosoId: data.motivoEndosoId,
        polizaId: data.polizaId,
        inicioVigenciaEndoso: data.inicioVigenciaEndoso,
        observaciones: data.observaciones,
        fechaAlta: data.fechaAlta,
        numSolicitud: data.numSolicitud,
        certificadoFileName: "No aplica",
        cartaFileName: "No aplica",
        actaNacimientoFileName: "No aplica",

        asegurados: [
          {
            nombre: data.nombre,
            apellidoPaterno: data.apellidoPaterno,
            apellidoMaterno: data.apellidoMaterno,
            relacionId: data.relacionId,
            sexoId: data.sexoId,
            fechaNacimiento: data.fechaNacimiento,
            numCertifiicado: data.numCertifiicado,
          },
        ],
      };
      return processedData;
    }
    case "C": {
      const processedData = {
        tipoSolicitudId: data.tipoSolicitudId,
        motivoEndosoId: data.motivoEndosoId,
        polizaId: data.polizaId,
        inicioVigenciaEndoso: data.inicioVigenciaEndoso,
        observaciones: data.observaciones,
        fechaAlta: data.fechaAlta,
        actaNacimientoFile: data.actaNacimientoFile,
        numSolicitud: data.numSolicitud,
        certificadoFileName: "No aplica",
        cartaFileName: "No aplica",
        asegurados: [
          {
            nombre: data.nombre,
            apellidoPaterno: data.apellidoPaterno,
            apellidoMaterno: data.apellidoMaterno,
            relacionId: data.relacionId,
            sexoId: data.sexoId,
            fechaNacimiento: data.fechaNacimiento,
            actaNacimientoFileName: data.actaFileName,
            actaNacimientoFileURL: data.actaFileURL,
            numCertifiicado: data.numCertifiicado,
          },
        ],
      };
      return processedData;
    }
    case "D": {
      const processedData = {
        tipoSolicitudId: data.tipoSolicitudId,
        motivoEndosoId: data.motivoEndosoId,
        polizaId: data.polizaId,
        inicioVigenciaEndoso: data.inicioVigenciaEndoso,
        observaciones: data.observaciones,
        fechaBaja: data.fechaBaja,
        numSolicitud: data.numSolicitud,
        certificadoFileName: "No aplica",
        cartaFileName: "No aplica",
        actaNacimientoFileName: "No aplica",
        asegurados: [
          {
            nombre: data.nombre,
            apellidoPaterno: data.apellidoPaterno,
            apellidoMaterno: data.apellidoMaterno,
            relacionId: data.relacionId,
            sexoId: data.sexoId,
            fechaNacimiento: data.fechaNacimiento,
            numCertifiicado: data.numCertifiicado,
          },
        ],
      };
      //(processedData);
      return processedData;
    }
    default:
      return null;
  }
}
