import React from "react";
import PropTypes from "prop-types";
import CustomTypography from "components/CustomTypography";

const ErrorList = ({ errorList }) => {
  return (
    <div
      style={{
        marginTop: "4rem",
        marginBottom: "4rem",
      }}
    >
      {errorList.length > 0 && (
        <div>
          <CustomTypography variant="h5" component="h6" color="error">
            Errores encontrados en el archivo: {errorList.length}
          </CustomTypography>
          <ul>
            {errorList.map((error, index) => (
              <CustomTypography key={index} variant="body1" component="li" color="error">
                {error}
              </CustomTypography>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ErrorList;

ErrorList.propTypes = {
  errorList: PropTypes.array.isRequired,
};
