import React, { useEffect, useState } from "react";
import { AppBar, Avatar, Backdrop, CircularProgress, Grid, Toolbar } from "@mui/material";

import user_placerholder from "assets/images/user_placeholder.png";
import CustomButton from "components/CustomButton";
import { useAuth, auth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import NavMenu from "./sections/NavMenu";

import Swal from "sweetalert2";
import CustomTypography from "components/CustomTypography";

const handleAccount = () => {
  console.log("View Account Info...");
};

function CustomNavbar(tabValue) {
  const email = auth.currentUser.email;
  const { logout } = useAuth();
  //remove everything after @ from email variable and store it in a new variable, split and only show 1st half
  const emailSplit = email.split("@", 1);

  const [imageData, setImageData] = useState(null);
  const [barBackground, setBarBackground] = useState("#039ECC");
  const [isNavbarLoading, setIsNavbarLoading] = useState(true);

  const user = useUser();

  useEffect(() => {
    setImageData(user?.user?.cliente.imagenBarra);
    setBarBackground(user?.user?.cliente.colorBarra);

    if (user) {
      const timer = setTimeout(() => {
        setIsNavbarLoading(false);
      }, 500);
    } else {
      setIsNavbarLoading(true);
    }
  }, [user]);

  const handleDashboard = () => {
    window.location = "/home";
  };

  const handleLogout = async () => {
    try {
      await logout();
      Swal.fire({
        icon: "success",
        title: "Sesión cerrada correctamente",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        window.location = "/inicio";
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal",
        footer: "Inténtalo de nuevo",
      });
      console.log(error);
    }
  };

  const test = true;

  return (
    <>
      {isNavbarLoading ? (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "grey",
              zIndex: 1,
            }}
          ></div>
          <Backdrop
            open={true}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "#fff",
              height: "100vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <>
          <AppBar
            style={{
              background: `${barBackground}`,
              height: "6rem",
            }}
          >
            <Toolbar
              style={{
                height: "8rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                sx={{ flexWrap: "wrap" }}
              >
                <Grid item xs={4} sm={8} md={10}>
                  <img
                    src={`data:image/png;base64,${imageData}`}
                    alt="Cliente logo"
                    onClick={handleDashboard}
                    style={{
                      maxWidth: "10rem",
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: { xs: "row", md: "column" },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      alt="User placeholder"
                      src={user_placerholder}
                      style={{
                        position: "relative",
                        top: "0.4rem",
                        height: { xs: "1rem", md: "2.5rem" },
                        width: { xs: "1rem", md: "2.5rem" },
                      }}
                    />
                    <CustomTypography
                      variant="button"
                      color="white"
                      sx={{
                        position: "relative",
                        top: "0.5rem",
                      }}
                    >
                      {emailSplit}
                    </CustomTypography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  md={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    style={{
                      position: "relative",
                      top: "0rem",
                      width: "6rem",
                      height: "1rem",
                      borderRadius: "0.8rem",
                      paddingTop: "0",
                      paddingBottom: "0",
                      flexDirection: { xs: "row", md: "column" },
                    }}
                    size="small"
                    onClick={handleLogout}
                  >
                    <CustomTypography
                      variant="button"
                      color="white"
                      sx={{
                        fontSize: "0.7rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Cerrar sesión
                    </CustomTypography>
                  </CustomButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <NavMenu value={tabValue} />
        </>
      )}
    </>
  );
}

export default CustomNavbar;
