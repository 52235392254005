import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CustomBox from "components/CustomBox";
import InfoCard from "structures/InfoCard/InfoCard";
import CustomTypography from "components/CustomTypography";
import { useMediaQuery } from "@mui/material";

//make unordered list of information
const polizaList = [
  "Dar de alta GMM individual o masivamente",
  "Consultar formatos para altas o bajas",
  "Recibir notificaciones de los status de tus solicitudes",
  "Editar o eliminar altas que presenten error",
];

const layoutList = [
  "Consultar tu polizario",
  "Descargar el template de la aseguradora que requieras",
];

function PolicyData() {
  const isXsScreen = useMediaQuery("(max-width: 600px)");
  return (
    <CustomBox width="90%" height="100vh" mx="auto" position="relative" top="10rem">
      <Container>
        <CustomTypography
          variant="h4"
          fontWeight="bold"
          mb={2}
          textAlign="center"
          sx={{ fontSize: isXsScreen ? "1.2rem" : "1.5rem" }}
        >
          ¡Bienvenido a tu portal de administración para Gastos Médicos Mayores!
        </CustomTypography>
        <CustomTypography
          variant="h5"
          fontWeight="bold"
          mb={2}
          textAlign="center"
          sx={{ fontSize: isXsScreen ? "1rem" : "1.2rem" }}
        >
          ¿Qué puedes hacer?
        </CustomTypography>
        <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <InfoCard
              title=""
              description={
                <div>
                  {polizaList.map((item) => (
                    <CustomTypography
                      key={item.id}
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["sm"],
                        },
                      })}
                    >
                      <ul>
                        <li>{item} </li>
                      </ul>
                    </CustomTypography>
                  ))}
                </div>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InfoCard
              title=""
              description={
                <div>
                  {layoutList.map((item) => (
                    <CustomTypography
                      key={item.id}
                      sx={({ breakpoints, typography: { size } }) => ({
                        [breakpoints.down("md")]: {
                          fontSize: size["sm"],
                        },
                      })}
                    >
                      <ul>
                        <li>{item} </li>
                      </ul>
                    </CustomTypography>
                  ))}
                </div>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </CustomBox>
  );
}

export default PolicyData;
