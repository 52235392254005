import React, { useEffect, useState } from "react";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import Swal from "sweetalert2";
import { Alert, FormControl, Paper, Popover, Snackbar } from "@mui/material";
import Select from "@mui/material/Select";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from "@mui/material/CircularProgress";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ContactPageIcon from "@mui/icons-material/ContactPage";

import CustomTypography from "components/CustomTypography";
import CustomBox from "components/CustomBox";
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { useStep, useStepUpdate } from "./StepContext";
import { useData, useDataUpdate } from "./DataContext";
import { useUser } from "../../../context/UserContext";

import "./styles.css";
import MassiveFormat from "./MassiveFormat";
import { useDialogUpdate } from "./DialogContext";
import axios from "axios";
import { auth } from "../../../context/AuthContext";
import { useIndex } from "./IndexContext";
import { useMassiveDialogUpdate } from "pages/MassiveSummaryTable/sections/MassiveDialogContext";
import { set } from "lodash";

const dateFormat = "YYYY-MM-DD";

const MassiveFormContentEdit = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCarta, setAnchorElCarta] = useState(null);
  const step = useStep();
  const toggleStep = useStepUpdate();
  const data = useData();
  const updateData = useDataUpdate();
  const toggleDialog = useDialogUpdate();
  const toggleMassiveDialog = useMassiveDialogUpdate();
  const editIndex = useIndex();

  const [isLoading, setIsLoading] = useState(true);

  const [tipoSolicitudId, setTipoSolicitudId] = useState(0);
  const [motivoEndosoId, setMotivoEndosoId] = useState(0);
  const [polizaId, setPolizaId] = useState(0);
  const [inicioVigenciaEndoso, setInicioVigenciaEndoso] = useState();
  const [observaciones, setObservaciones] = useState("");
  const [fechaAlta, setFechaAlta] = useState(dayjs().format(dateFormat));
  const [fechaBaja, setFechaBaja] = useState(dayjs().format(dateFormat));
  const [numCertifiicado, setNumCertifiicado] = useState();
  const [cartaReconocimientoFile, setCartaReconocimientoFile] = useState("");
  const [certificadoFile, setCertificadoFile] = useState("");
  const [fechaCartaReconocimiento, setFechaCartaReconocimiento] = useState();
  const [actaNacimientoFile, setActaNacimientoFile] = useState("");
  const [statusId, setStatusId] = useState(0);
  const [numSolicitud, setNumSolicitud] = useState("");

  const [nombre, setNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [relacionId, setRelacionId] = useState(0);
  const [sexoId, setSexoId] = useState(0);

  const user = useUser();
  const clientIdentification = user.clientID;

  const [cliente, setCliente] = useState("");

  const [fechaNacimiento, setFechaNacimiento] = useState();
  const [submitButton, setSubmitButton] = useState(false);
  const [certificadoFileURL, setCertificadoFileURL] = useState(null);
  const [cartaFileURL, setCartaFileURL] = useState(null);
  const [actaFileURL, setActaFileURL] = useState(null);

  const [userValues, setUserValues] = useState([]);
  const [solicitudValues, setSolicitudValues] = useState([]);
  const [endosoValues, setEndosoValues] = useState([]);
  const [razonesSocialesValues, setRazonesSocialesValues] = useState([]);
  const [polizaValues, setPolizaValues] = useState([]);
  const [relationValues, setRelationValues] = useState([]);
  const [sexValues, setSexValues] = useState([]);
  const [cartaFileName, setCartaFileName] = useState("");
  const [certificadoFileName, setCertificadoFileName] = useState("");
  const [actaFileName, setActaFileName] = useState("");

  const [tipoSolicitudIdError, setTipoSolicitudIdError] = useState(true);
  const [motivoEndosoIdError, setMotivoEndosoIdError] = useState(true);
  const [clienteError, setClienteError] = useState(true);
  const [polizaIdError, setPolizaIdError] = useState(true);
  const [inicioVigenciaEndosoError, setInicioVigenciaEndosoError] = useState(true);
  const [fechaCartaReconocimientoError, setFechaCartaReconocimientoError] = useState(true);
  const [fechaCartaReconocimientoError2, setFechaCartaReconocimientoError2] = useState(true);
  const [observacionesError, setObservacionesError] = useState(true);
  const [numCertifiicadoError, setNumCertifiicadoError] = useState(true);
  const [cartaReconocimientoFileError, setCartaReconocimientoFileError] = useState(true);
  const [certificadoFileError, setCertificadoFileError] = useState(true);
  const [actaNacimientoError, setActaNacimientoError] = useState(true);

  const [relacionIdError, setRelacionIdError] = useState(true);
  const [nombreError, setNombreError] = useState(true);
  const [apellidoPaternoError, setApellidoPaternoError] = useState(true);
  const [apellidoMaternoError, setApellidoMaternoError] = useState(true);
  const [sexoIdError, setSexoIdError] = useState(true);
  const [fechaNacimientoError, setFechaNacimientoError] = useState(true);

  const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

  useEffect(() => {
    if (editIndex !== null) {
      toggleStep(2);
      if (data.length > 0) {
        // Set data of step 1
        if (editIndex > -1) {
          setActaFileName(data[editIndex].asegurados[0].actaNacimientoFileName);
          setActaFileURL(data[editIndex].asegurados[0].actaNacimientoFileURL);
          setActaNacimientoFile(data[editIndex].asegurados[0].actaNacimientoFile);
          setActaNacimientoError(false);
          setCartaFileName(data[editIndex].asegurados[0].cartaFileName);
          setCartaFileURL(data[editIndex].asegurados[0].cartaFileURL);
          setCartaReconocimientoFile(data[editIndex].asegurados[0].cartaReconocimientoFile);
          setCartaReconocimientoFileError(false);
          setCertificadoFile(data[editIndex].asegurados[0].certificadoFile);
          setCertificadoFileError(false);
          setCertificadoFileName(data[editIndex].asegurados[0].certificadoFileName);
          setCertificadoFileURL(data[editIndex].asegurados[0].certificadoFileURL);
        }
        setClienteError(false);
        setFechaAlta(data[0].fechaAlta);
        setFechaBaja(data[0].fechaBaja);
        setFechaCartaReconocimiento(data[editIndex]?.fechaCartaReconocimiento);
        setFechaCartaReconocimientoError(false);
        setInicioVigenciaEndoso(data[0].inicioVigenciaEndoso);
        setInicioVigenciaEndosoError(false);
        setMotivoEndosoId(data[0].motivoEndosoId);
        setMotivoEndosoIdError(false);
        setNumSolicitud(data[0].numSolicitud);
        setObservaciones(data[0].observaciones);
        setObservacionesError(false);
        setPolizaId(data[editIndex].polizaId);
        setPolizaIdError(false);
        setStatusId(data[0].statusId);
        setTipoSolicitudId(data[editIndex].tipoSolicitudId);
        setTipoSolicitudIdError(false);
        if (editIndex > -1) {
          // Set data of step 2
          setRelacionId(data[editIndex].asegurados[0].relacionId);
          setRelacionIdError(false);
          setNombre(data[editIndex].asegurados[0].nombre);
          setNombreError(false);
          setApellidoPaterno(data[editIndex].asegurados[0].apellidoPaterno);
          setApellidoPaternoError(false);
          setApellidoMaterno(data[editIndex].asegurados[0].apellidoMaterno);
          setApellidoMaternoError(false);
          setSexoId(data[editIndex].asegurados[0].sexoId);
          setSexoIdError(false);
          setFechaNacimiento(data[editIndex].asegurados[0].fechaNacimiento);
          setFechaNacimientoError(false);
          setFechaCartaReconocimientoError2(false);
          setFechaCartaReconocimiento(data[editIndex]?.asegurados[0].fechaCartaReconocimiento);
          setNumCertifiicado(data[editIndex]?.asegurados[0].numCertifiicado);
          setNumCertifiicadoError(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    const fetchSolicitudValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/tipos-solicitud", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          //only show the first four options and change the text of the last one to "Bajas"
          response.data.splice(3, 2, { id: 4, tipo: "Baja de asegurado" });
          setSolicitudValues(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchSolicitudValues();
  }, []);

  useEffect(() => {
    const fetchEndosoValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/motivos-endoso", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setEndosoValues(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchEndosoValues();
  }, []);

  useEffect(() => {
    const fetchRazonesSocialesValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/clientes/" + clientIdentification + "/razones-sociales", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setRazonesSocialesValues(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchRazonesSocialesValues();
  }, [clientIdentification]);

  useEffect(() => {
    const fetchPolizaValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/razones-sociales/" + cliente + "/polizas", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setPolizaValues(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchPolizaValues();
  }, [cliente]);

  useEffect(() => {
    setIsLoading(true);
    const fetchRelationValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/tipos-relacion", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setRelationValues(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchRelationValues();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchSexValues = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .get(url + "/sexos", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        })
        .then((response) => {
          setSexValues(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchSexValues();
    setIsLoading(false);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleAnterior = (e) => {
    e.preventDefault();
    toggleStep(1);
  };

  //function that returns a red dot warning if the fields are empty
  function validateFirstStep(
    tipoSolicitudId,
    motivoEndosoId,
    cliente,
    polizaId,
    inicioVigenciaEndoso,
    observaciones,
    cartaReconocimientoFile,
    certificadoFile,
    actaNacimientoFile
  ) {
    if (tipoSolicitudId === 0) {
      setOpen(true);
      setTipoSolicitudIdError(true);
    } else if (actaNacimientoFile === true && tipoSolicitudId === 3) {
      setOpen(true);
      setActaNacimientoError(true);
    } else if (cartaReconocimientoFile === "" && tipoSolicitudId === 1) {
      setOpen(true);
      setCartaReconocimientoFileError(true);
    } else if (certificadoFile === "" && tipoSolicitudId === 1) {
      setOpen(true);
      setCertificadoFileError(true);
    } else if (fechaCartaReconocimiento === undefined && tipoSolicitudId === 1) {
      setOpen(true);
      setFechaCartaReconocimientoError(true);
    } else if (motivoEndosoId === 0) {
      setOpen(true);
      setMotivoEndosoIdError(true);
    } else if (cliente === "") {
      setClienteError(true);
      setOpen(true);
    } else if (polizaId === 0) {
      setOpen(true);
      setPolizaIdError(true);
    } else if (inicioVigenciaEndoso === undefined || inicioVigenciaEndoso === "") {
      setOpen(true);
      setInicioVigenciaEndosoError(true);
    } else if (observaciones === "") {
      setOpen(true);
      setObservacionesError(true);
    } else {
      toggleStep(2);
    }
  }

  function validateSecondStep(
    relacionId,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    sexoId,
    fechaNacimiento,
    numCertifiicado
  ) {
    if (relacionId === 0) {
      setRelacionIdError(true);
      setOpen(true);
    } else if (nombre === "") {
      setNombreError(true);
      setOpen(true);
    } else if (apellidoPaterno === "") {
      setApellidoPaternoError(true);
      setOpen(true);
    } else if (apellidoMaterno === "") {
      setApellidoMaternoError(true);
      setOpen(true);
    } else if (sexoId === 0) {
      setSexoIdError(true);
      setOpen(true);
    } else if (fechaNacimiento === undefined || fechaNacimiento === "") {
      setFechaNacimientoError(true);
      setOpen(true);
    } else if (
      (fechaCartaReconocimiento === undefined || fechaCartaReconocimiento === "") &&
      tipoSolicitudId === "A"
    ) {
      setFechaCartaReconocimientoError2(true);
      setOpen(true);
    } else if (numCertifiicado === "") {
      setNumCertifiicadoError(true);
      setOpen(true);
    } else {
      updateData(parsedData, editIndex !== null && editIndex > -1, editIndex);
      //toggleDialog();
      toggleStep(1);
      toggleMassiveDialog();
    }
  }

  let rawData = {
    tipoSolicitudId,
    motivoEndosoId,
    polizaId,
    inicioVigenciaEndoso,
    observaciones,
    fechaAlta,
    fechaBaja,
    numCertifiicado,
    cartaReconocimientoFile,
    fechaCartaReconocimiento,
    certificadoFile,
    actaNacimientoFile,
    cartaFileURL,
    certificadoFileURL,
    actaFileURL,
    cartaFileName,
    certificadoFileName,
    actaFileName,
    statusId,
    numSolicitud,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    relacionId,
    sexoId,
    fechaNacimiento,
    cliente,
  };

  const parsedData = MassiveFormat(rawData);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCartaPopoverOpen = (event) => {
    setAnchorElCarta(event.currentTarget);
  };

  const handleCartaPopoverClose = () => {
    setAnchorElCarta(null);
  };

  const openInfo = Boolean(anchorEl);

  const openCartaInfo = Boolean(anchorElCarta);

  const handleOrderTypeChange = (event) => {
    setTipoSolicitudId(event.target.value);
    setTipoSolicitudIdError(false);
    if (event.target.value === 1 || event.target.value === 2) {
      setMotivoEndosoId(1);
      setMotivoEndosoIdError(false);
    } else if (event.target.value === 3) {
      setRelacionId(3);
      setMotivoEndosoId(1);
      setMotivoEndosoIdError(false);
    } else {
      setMotivoEndosoId(2);
      setMotivoEndosoIdError(false);
    }
  };

  const handleMotivoEndosoChange = (event) => {
    setMotivoEndosoId(event.target.value);
    setMotivoEndosoIdError(false);
  };

  const handleClienteChange = (event) => {
    setCliente(event.target.value);
    setClienteError(false);
    setIsLoading(true);
  };

  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
    setObservacionesError(false);
  };

  const handlePolizaChange = (event) => {
    setPolizaId(event.target.value);
    setPolizaIdError(false);
  };

  const handleRelationChange = (event) => {
    setRelacionId(event.target.value);
    setRelacionIdError(false);
  };

  const handleNameChange = (event) => {
    const value = event.target.value.toUpperCase(); // Convert to uppercase
    setNombre(value);
    setNombreError(false);
  };

  const handleAPChange = (event) => {
    const value = event.target.value.toUpperCase(); // Convert to uppercase
    setApellidoPaterno(value);
    setApellidoPaternoError(false);
  };

  const handleAMChange = (event) => {
    const value = event.target.value.toUpperCase(); // Convert to uppercase
    setApellidoMaterno(value);
    setApellidoMaternoError(false);
  };

  const handleFechaCartaChange = (date, dateString) => {
    if (dateString) {
      const selectedDate = dayjs(dateString, dateFormat).format("YYYY-MM-DD");
      setFechaCartaReconocimiento(selectedDate);
      setFechaCartaReconocimientoError(false);
      setFechaCartaReconocimientoError2(false);
    } else {
      setFechaCartaReconocimiento("");
      setFechaCartaReconocimientoError(true);
      setFechaCartaReconocimientoError2(true);
    }
  };

  const handleFechaInicioEndosoChange = (date, dateString) => {
    if (dateString) {
      const selectedDate = dayjs(dateString, dateFormat).format("YYYY-MM-DD");
      setInicioVigenciaEndoso(selectedDate);
      setInicioVigenciaEndosoError(false);
    } else {
      setInicioVigenciaEndoso("");
      setInicioVigenciaEndosoError(true); // Set the error to true if the date is cleared
    }
  };

  const handleNoCertificadoChange = (event) => {
    setNumCertifiicado(event.target.value);
    setNumCertifiicadoError(false);
  };

  const handleFechaNacimientoChange = (date, dateString) => {
    if (dateString) {
      const selectedDate = dayjs(dateString, dateFormat).format("YYYY-MM-DD");
      setFechaNacimiento(selectedDate);
      setFechaNacimientoError(false);
    } else {
      setFechaNacimiento("");
      setFechaNacimientoError(true); // Set the error to true if the date is cleared
    }
  };

  const handleSexoChange = (event) => {
    setSexoId(event.target.value);
    setSexoIdError(false);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCartaFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type !== "application/pdf") {
      setCartaReconocimientoFileError(true);
      setCartaFileName("");
      Swal.fire({
        title: "Error",
        text: "Por favor, selecciona un archivo PDF.",
        toast: true,
        position: "top-end",
        timer: 6000,
        icon: "error",
        color: "#fff",
        background: "#EA5151",
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          container: "my-swal",
        },
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      return;
    } else {
      setCartaReconocimientoFileError(false);
    }
    const base64 = await convertBase64(file);
    const fileName = event.target.files[0].name;
    setCartaReconocimientoFileError(false);
    setCartaReconocimientoFile(base64);
    setCartaFileName(fileName);
    setCartaFileURL(URL.createObjectURL(file));
  };

  const handleCertificadoFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type !== "application/pdf") {
      setCertificadoFileError(true);
      setCertificadoFileName("");
      Swal.fire({
        title: "Error",
        text: "Por favor, selecciona un archivo PDF.",
        toast: true,
        position: "top-end",
        timer: 6000,
        icon: "error",
        color: "#fff",
        background: "#EA5151",
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          container: "my-swal",
        },
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    } else {
      setCertificadoFileError(false);
    }

    const base64 = await convertBase64(file);
    const fileName = event.target.files[0].name;
    setCertificadoFileError(false);
    setCertificadoFile(base64);
    setCertificadoFileName(fileName);
    setCertificadoFileURL(URL.createObjectURL(file));
  };

  const handleActaNacimientoFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type !== "application/pdf") {
      setActaNacimientoError(true);
      setActaFileName("");
      Swal.fire({
        title: "Error",
        text: "Por favor, selecciona un archivo PDF.",
        toast: true,
        position: "top-end",
        timer: 6000,
        icon: "error",
        color: "#fff",
        background: "#EA5151",
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          container: "my-swal",
        },
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    } else {
      setActaNacimientoError(false);
    }
    const base64 = await convertBase64(file);
    const fileName = event.target.files[0].name;
    setActaNacimientoError(false);
    setActaNacimientoFile(base64);
    setActaFileName(fileName);
    setActaFileURL(URL.createObjectURL(file));
  };

  const tipoSolicitudIdErrorValidator =
    (tipoSolicitudId === 1 &&
      (!cartaReconocimientoFile || !certificadoFile || !fechaCartaReconocimiento)) ||
    (tipoSolicitudId === 3 && !actaNacimientoFile) ||
    tipoSolicitudId === 0;

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Por favor llene todos los campos requeridos:
          {tipoSolicitudId === 0 ? " Tipo de Solicitud, " : ""}
          {actaNacimientoError === true && tipoSolicitudId === 3 ? " Acta de Nacimiento, " : ""}
          {cartaFileName === "" && tipoSolicitudId === 1 ? " Carta de reconocimiento, " : ""}
          {certificadoFileName === "" && tipoSolicitudId === 1 ? " Certificado, " : ""}
          {(fechaCartaReconocimiento === undefined || fechaCartaReconocimiento === "") &&
          tipoSolicitudId === "A"
            ? " Fecha de carta, "
            : ""}
          {motivoEndosoId === 0 ? " Motivo de Endoso, " : ""}
          {cliente === "" && step === false ? " Cliente, " : ""}
          {polizaId === 0 ? " Póliza, " : ""}
          {(inicioVigenciaEndoso === undefined || inicioVigenciaEndoso === "") && step === false
            ? " Fecha de alta en póliza, "
            : ""}
          {observaciones === "" ? " Observaciones, " : ""}
          {numCertifiicado === "" ? " Número de certificado, " : ""}
          {relacionId === 0 && step === true ? " Parentesco, " : ""}
          {nombre === "" && step === true ? " Nombre, " : ""}
          {apellidoPaterno === "" && step === true ? " Apellido Paterno, " : ""}
          {apellidoMaterno === "" && step === true ? " Apellido Materno, " : ""}
          {sexoId === 0 && step === true ? " Sexo, " : ""}
          {fechaNacimiento === undefined || (fechaNacimiento === "" && step === true)
            ? " Fecha de nacimiento, "
            : ""}
        </Alert>
      </Snackbar>

      {step === false && (editIndex === null || editIndex === -1) && data.length === 0 && (
        <>
          {isLoading === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-50px",
                marginLeft: "-50px",
              }}
            />
          ) : (
            <>
              <CustomBox display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                <CustomBox
                  gridColumn="span 3"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <CustomTypography
                    fontSize="0.9rem"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                  >
                    Tipo de orden de trabajo
                  </CustomTypography>
                </CustomBox>

                {tipoSolicitudIdErrorValidator ? (
                  <>
                    <CustomBox gridColumn="span 1" sx={{ alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}

                <CustomBox
                  gridColumn="span 8"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <FormControl fullWidth required="true">
                    <Select
                      labelId="order-type"
                      id="order-type"
                      value={tipoSolicitudId}
                      onChange={handleOrderTypeChange}
                      sx={{
                        height: "4vh",
                        backgroundColor: "rgba(217, 217, 217, 0.34)",
                        border: "none",
                        width: "60%",
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownOutlinedIcon
                          color="black"
                          sx={{ fontSize: "3rem", mr: 1 }}
                        />
                      )}
                    >
                      {solicitudValues.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          sx={{ backgroundColor: "inherit", fontWeight: "bold" }}
                        >
                          {item.tipo}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomBox>

                {/* TEXTO Y BOTONES PARA SUBIR ARCHIVOS DE CARTA DE RECONOCIMIENTO Y CERTIFICADO */}
                {tipoSolicitudId === 1 ? (
                  <>
                    <CustomBox gridColumn="span 3"></CustomBox>
                    {cartaReconocimientoFileError === true ? (
                      <>
                        <CustomBox
                          gridColumn="span 1"
                          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                        >
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}
                    <CustomBox
                      gridColumn="span 3"
                      sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                    >
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        CARTA DE RECONOCIMIENTO
                        <IconButton
                          aria-label="help"
                          onMouseEnter={handleCartaPopoverOpen}
                          onMouseLeave={handleCartaPopoverClose}
                        >
                          <InfoIcon sx={{ fontSize: "1.5rem", color: "#FFC805" }} />
                        </IconButton>
                        {/* POP-UP DE AYUDA EN CARTA DE RECONOCIMIENTO DE ANTIGUEDAD */}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openCartaInfo}
                          anchorEl={anchorElCarta}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          onClose={handleCartaPopoverClose}
                          disableRestoreFocus
                        >
                          <Paper sx={{ p: 1, bgcolor: "#FFC805", width: "50%" }} elevation={1}>
                            <CustomTypography
                              sx={{
                                p: 1,
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                                color: "#fff",
                                textAlign: "center",
                              }}
                            >
                              Te recordamos que para reconocer la antigüedad no debe de exceder los
                              30 días naturales de baja en tu última póliza
                            </CustomTypography>
                          </Paper>
                        </Popover>
                      </CustomTypography>
                    </CustomBox>
                    {certificadoFileError === true ? (
                      <>
                        <CustomBox
                          gridColumn="span 2"
                          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                        >
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 2"></CustomBox>
                      </>
                    )}
                    <CustomBox
                      gridColumn="span 3"
                      sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                    >
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                      >
                        CERTIFICADO
                      </CustomTypography>
                    </CustomBox>

                    <CustomBox gridColumn="span 4"></CustomBox>
                    <CustomBox gridColumn="span 4">
                      <CustomButton
                        variant="contained"
                        color="secondary"
                        component="label"
                        size="small"
                        sx={{
                          padding: "0.2rem",
                          width: "60%",
                          marginLeft: "1rem",
                        }}
                      >
                        <CustomTypography
                          variant="button"
                          sx={{ fontWeight: "regular", color: "#fff", fontSize: "0.8rem" }}
                        >
                          Seleccionar Documento
                        </CustomTypography>
                        <input
                          type="file"
                          onChange={handleCartaFileUpload}
                          accept="application/pdf"
                          hidden
                          required="true"
                        />
                      </CustomButton>
                    </CustomBox>

                    <CustomBox gridColumn="span 4">
                      <CustomButton
                        variant="contained"
                        color="secondary"
                        component="label"
                        size="small"
                        sx={{
                          padding: "0.4rem",
                          width: "60%",
                          marginLeft: "2rem",
                        }}
                      >
                        <CustomTypography
                          variant="button"
                          sx={{
                            fontWeight: "regular",
                            color: "#fff",
                            fontSize: "0.8rem",
                          }}
                        >
                          Seleccionar Documento
                        </CustomTypography>
                        <input
                          type="file"
                          onChange={handleCertificadoFileUpload}
                          accept="application/pdf"
                          hidden
                        />
                      </CustomButton>
                    </CustomBox>

                    {/*AQUI SE MUESTRAN LOS NOMBRES DE LOS ARCHIVOS*/}
                    <CustomBox gridColumn="span 4"></CustomBox>
                    <CustomBox gridColumn="span 4">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "regular",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {cartaFileName ? cartaFileName : "No se ha subido ningún archivo"}
                      </CustomTypography>
                    </CustomBox>
                    <CustomBox gridColumn="span 4">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "regular",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "1.5rem",
                        }}
                      >
                        {certificadoFileName
                          ? certificadoFileName
                          : "No se ha subido ningún archivo"}
                      </CustomTypography>
                    </CustomBox>

                    {/*FECHA DE CARTA PARA LA OPCION DE ALTA CON RECONOCIMIENTO DE ANTIGUEDAD*/}
                    <CustomBox gridColumn="span 3"></CustomBox>
                    {fechaCartaReconocimientoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}
                    <CustomBox gridColumn="span 2">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        FECHA DE CARTA
                      </CustomTypography>
                    </CustomBox>

                    <CustomBox
                      gridColumn="span 2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker
                        value={
                          //convert the date to a dayjs object
                          fechaCartaReconocimiento ? dayjs(fechaCartaReconocimiento) : ""
                        }
                        placeholder="aaaa-mm-dd"
                        format={dateFormat}
                        size="small"
                        required="true"
                        //limit the date selection up to 30 days in the past
                        disabledDate={(current) =>
                          current && current < dayjs().endOf("day").subtract(30, "days")
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={handleFechaCartaChange}
                        popupStyle={{
                          top: "20%",
                          left: "50%",
                        }}
                      />
                    </CustomBox>
                    <CustomBox gridColumn="span 4"></CustomBox>
                  </>
                ) : (
                  <></>
                )}
                {tipoSolicitudId === 3 ? (
                  <>
                    <CustomBox gridColumn="span 3"></CustomBox>
                    {actaNacimientoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox gridColumn="span 4">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Acta de Nacimiento
                      </CustomTypography>
                    </CustomBox>
                    <CustomBox gridColumn="span 4"></CustomBox>

                    <CustomBox gridColumn="span 4"></CustomBox>
                    <CustomBox textAlign="center" gridColumn="span 4">
                      <CustomButton
                        variant="contained"
                        color="secondary"
                        component="label"
                        size="small"
                        sx={{
                          padding: "0.4rem",
                          width: "60%",
                          marginBottom: "1rem",
                        }}
                      >
                        <CustomTypography
                          variant="button"
                          sx={{
                            fontWeight: "regular",
                            color: "#fff",
                            fontSize: "0.8rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Seleccionar Documento
                        </CustomTypography>
                        <input
                          type="file"
                          onChange={handleActaNacimientoFileUpload}
                          accept="application/pdf"
                          hidden
                        />
                      </CustomButton>
                    </CustomBox>
                    <CustomBox gridColumn="span 4">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{ fontWeight: "regular", display: "flex", alignItems: "center" }}
                      >
                        {actaFileName ? actaFileName : "No se ha subido ningún archivo"}
                      </CustomTypography>
                    </CustomBox>
                  </>
                ) : (
                  <></>
                )}

                {/* MOTIVO DE ENDOSO */}
                <CustomBox
                  gridColumn="span 3"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                  >
                    Motivo de endoso
                  </CustomTypography>
                </CustomBox>
                {motivoEndosoIdError === true ? (
                  <>
                    <CustomBox gridColumn="span 1" sx={{ alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox
                  gridColumn="span 8"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <FormControl fullWidth required="true">
                    <Select
                      className="inter-select"
                      disabled={true}
                      labelId="motivoEndosoId"
                      id="motivoEndosoId"
                      value={motivoEndosoId}
                      //onChange={handleMotivoEndosoChange}
                      IconComponent={() => (
                        <ContactPageIcon
                          color="primary"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: "8px",
                            fontSize: "3rem",
                          }}
                        />
                      )}
                      sx={{
                        height: "4vh",
                        backgroundColor: "rgba(217, 217, 217, 0.34)",
                        border: "none",
                        width: "60%",
                        paddingLeft: "16px",
                        color: "#000",
                      }}
                    >
                      {endosoValues.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "32px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.motivoEndoso}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomBox>

                {/* CLIENTE */}
                <CustomBox
                  gridColumn="span 3"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                  >
                    Razón Social
                  </CustomTypography>
                </CustomBox>
                {clienteError === true ? (
                  <>
                    <CustomBox gridColumn="span 1" sx={{ alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox
                  gridColumn="span 8"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <FormControl fullWidth required="true">
                    <Select
                      className="inter-select"
                      labelId="cliente"
                      id="cliente"
                      value={cliente}
                      onChange={handleClienteChange}
                      IconComponent={() => (
                        <>
                          <FolderSharedIcon
                            color="primary"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              left: "8px",
                              fontSize: "3rem",
                            }}
                          />
                          <KeyboardArrowDownOutlinedIcon
                            color="black"
                            sx={{ fontSize: "3rem", mr: 1 }}
                          />
                        </>
                      )}
                      sx={{
                        height: "4vh",
                        backgroundColor: "rgba(217, 217, 217, 0.34)",
                        border: "none",
                        width: "60%",
                        paddingLeft: "16px",
                        color: "#000",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {razonesSocialesValues.map((cliente) => (
                        <MenuItem key={cliente.id} value={cliente.id} sx={{ fontWeight: "bold" }}>
                          {cliente.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomBox>

                {/* NO POLIZA */}
                <CustomBox
                  gridColumn="span 3"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                  >
                    No. Póliza
                  </CustomTypography>
                </CustomBox>
                {polizaIdError === true ? (
                  <>
                    <CustomBox gridColumn="span 1" sx={{ alignItems: "center" }}>
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox
                  gridColumn="span 8"
                  sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                >
                  <FormControl fullWidth required="true">
                    <Select
                      labelId="no-poliza"
                      id="no-poliza"
                      value={polizaId}
                      onChange={handlePolizaChange}
                      sx={{
                        height: "4vh",
                        backgroundColor: "rgba(217, 217, 217, 0.34)",
                        border: "none",
                        width: "40%",
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownOutlinedIcon
                          color="black"
                          sx={{ fontSize: "3rem", mr: 1 }}
                        />
                      )}
                    >
                      {polizaValues.map((poliza) => (
                        <MenuItem
                          key={poliza.id}
                          value={poliza.id}
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {poliza.numeroPoliza}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomBox>
                {/* INICIO VIGENCIO ENDOSO */}

                <>
                  <CustomBox gridColumn="span 3">
                    <CustomTypography
                      fontSize="0.9rem"
                      sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                    >
                      Fecha alta en póliza
                    </CustomTypography>
                  </CustomBox>
                  {inicioVigenciaEndosoError === true ? (
                    <>
                      <CustomBox gridColumn="span 1">
                        <CircleIcon
                          sx={{
                            scale: ".5",
                            fontSize: "1.5rem",
                            color: "#EA5151",
                            float: "right",
                          }}
                        />
                      </CustomBox>
                    </>
                  ) : (
                    <>
                      <CustomBox gridColumn="span 1"></CustomBox>
                    </>
                  )}
                  <CustomBox
                    gridColumn="span 8"
                    sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                  >
                    <DatePicker
                      value={inicioVigenciaEndoso ? dayjs(inicioVigenciaEndoso, dateFormat) : ""}
                      placeholder="aaaa-mm-dd"
                      format={dateFormat}
                      size="small"
                      required="true"
                      getPopupContainer={(trigger) => trigger.parentElement}
                      onChange={handleFechaInicioEndosoChange}
                      popupStyle={{
                        top: "20%",
                        left: "50%",
                      }}
                    />
                  </CustomBox>
                </>
                {/* OBSERVACIONES */}

                <>
                  <CustomBox
                    gridColumn="span 3"
                    sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
                  >
                    <CustomTypography fontSize="0.9rem" sx={{ fontWeight: "bold", color: "#000" }}>
                      Observaciones de entrega
                    </CustomTypography>
                  </CustomBox>
                  {observacionesError === true ? (
                    <>
                      <CustomBox
                        gridColumn="span 1"
                        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                      >
                        <CircleIcon
                          sx={{
                            scale: ".5",
                            fontSize: "1.5rem",
                            color: "#EA5151",
                            float: "right",
                          }}
                        />
                      </CustomBox>
                    </>
                  ) : (
                    <>
                      <CustomBox gridColumn="span 1"></CustomBox>
                    </>
                  )}
                  <CustomBox
                    gridColumn="span 8"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <CustomTypography>
                      <textarea
                        placeholder=""
                        type="textarea"
                        rows="2"
                        cols={window.innerWidth < 800 ? "auto" : "60"}
                        onChange={handleObservacionesChange}
                        value={observaciones}
                        style={{
                          fontFamily: "Montserrat",
                          backgroundColor: "rgba(217, 217, 217, 0.34)",
                          border: "none",
                          borderRadius: "1vh",
                          resize: "none",
                          height: "auto",
                          width: "80%",
                          padding: "0.5rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                          outline: "none",
                        }}
                        required="true"
                      />
                    </CustomTypography>
                  </CustomBox>
                </>

                {/* SECCION DE BOTON Y AYUDA */}
                <>
                  <CustomBox
                    gridColumn="span 3"
                    sx={{
                      marginTop: "1rem",
                    }}
                  >
                    <IconButton
                      aria-label="help"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <InfoIcon
                        sx={{
                          fontSize: "1.5rem",
                          color: "#FFC805",
                        }}
                      />
                    </IconButton>

                    {/* POP-UP DE AYUDA */}
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={openInfo}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Paper sx={{ p: 1, bgcolor: "#FFC805" }} elevation={1}>
                        <CustomTypography
                          sx={{
                            p: 1,
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          ¿Necesitas ayuda?
                        </CustomTypography>
                        <CustomTypography
                          sx={{
                            p: 1,
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <LocalPhoneOutlinedIcon sx={{ fontSize: "1rem", mr: 1 }} />
                          800 800 1107
                          <EmailOutlinedIcon sx={{ fontSize: "1rem", mr: 1, ml: 2 }} />
                          avisosgmm@inter.mx
                        </CustomTypography>
                      </Paper>
                    </Popover>
                  </CustomBox>

                  {/* BOTON DE SIGUIENTE */}
                  <CustomBox
                    gridColumn="span 6"
                    sx={{
                      marginTop: "1rem",
                    }}
                  >
                    <CustomButton
                      variant="contained"
                      color="primary"
                      component="label"
                      size="small"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        width: "50%",
                        borderRadius: "2vh",
                      }}
                      type="submit"
                      onClick={() => {
                        validateFirstStep(
                          tipoSolicitudId,
                          motivoEndosoId,
                          cliente,
                          polizaId,
                          inicioVigenciaEndoso,
                          observaciones,
                          fechaAlta,
                          fechaBaja,
                          numCertifiicado,
                          cartaReconocimientoFile,
                          certificadoFile,
                          actaNacimientoFile
                        );
                      }}
                    >
                      <CustomTypography
                        variant="button"
                        sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                      >
                        Siguiente
                      </CustomTypography>
                    </CustomButton>
                  </CustomBox>

                  <CustomBox gridColumn="span 3"></CustomBox>
                </>
              </CustomBox>
            </>
          )}
        </>
      )}
      {step === true && (
        <>
          {isLoading === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-50px",
                marginLeft: "-50px",
              }}
            />
          ) : (
            <>
              {/* Main Grid */}
              <CustomBox display="grid" gridTemplateColumns="repeat(24, 1fr)" gap={2}>
                {/* Section with Parentesco, Nombre, A. Paterno & A. Materno */}
                {/* Tipo de Parentesco Title */}
                {relacionIdError === true ? (
                  <>
                    <CustomBox gridColumn="span 1">
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox gridColumn="span 5">
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: "start",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Parentesco
                  </CustomTypography>
                </CustomBox>

                {/* Nombre Title*/}
                {nombreError === true ? (
                  <>
                    <CustomBox gridColumn="span 1">
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox gridColumn="span 5">
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: "start",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Nombre
                  </CustomTypography>
                </CustomBox>

                {/* AP Title*/}
                {apellidoPaternoError === true ? (
                  <>
                    <CustomBox gridColumn="span 1">
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}
                <CustomBox gridColumn="span 5">
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: "start",
                      whiteSpace: "nowrap",
                    }}
                  >
                    A. Paterno
                  </CustomTypography>
                </CustomBox>

                {/* AM Title*/}

                {apellidoMaternoError === true ? (
                  <>
                    <CustomBox gridColumn="span 1">
                      <CircleIcon
                        sx={{
                          scale: ".5",
                          fontSize: "1.5rem",
                          color: "#EA5151",
                          float: "right",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    <CustomBox gridColumn="span 1"></CustomBox>
                  </>
                )}

                <CustomBox textAlign="center" gridColumn="span 5">
                  <CustomTypography
                    fontSize="0.9rem"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      textAlign: "start",
                      whiteSpace: "nowrap",
                    }}
                  >
                    A. Materno
                  </CustomTypography>
                </CustomBox>

                {/* Parentesco Select */}
                <CustomBox gridColumn="span 6">
                  <FormControl fullWidth required="true">
                    <Select
                      disabled={tipoSolicitudId === 3}
                      value={relacionId}
                      defaultValue={null}
                      labelId="relacionId"
                      id="relacionId"
                      onChange={handleRelationChange}
                      sx={{
                        height: "4.5vh",
                        backgroundColor: "rgba(217, 217, 217, 0.34)",
                        border: "none",
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownOutlinedIcon
                          color="black"
                          sx={{ fontSize: "3rem", mr: 1 }}
                        />
                      )}
                    >
                      {relationValues.map((relation) => (
                        <MenuItem value={relation.id} key={relation.id} sx={{ fontWeight: "bold" }}>
                          {relation.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomBox>

                {/* Nombre Input */}
                <CustomBox gridColumn="span 6">
                  <CustomInput
                    type="text"
                    fullWidth
                    value={nombre}
                    onChange={handleNameChange}
                    required="true"
                    sx={{
                      backgroundColor: "rgba(217, 217, 217, 0.34)",
                      border: "none",
                    }}
                  ></CustomInput>
                </CustomBox>

                {/* AP Input */}
                <CustomBox gridColumn="span 6">
                  <CustomInput
                    type="text"
                    fullWidth
                    value={apellidoPaterno}
                    onChange={handleAPChange}
                    required="true"
                    sx={{ backgroundColor: "rgba(217, 217, 217, 0.34)", border: "none" }}
                  ></CustomInput>
                </CustomBox>

                {/* AM Input*/}
                <CustomBox gridColumn="span 6">
                  <CustomInput
                    type="text"
                    fullWidth
                    value={apellidoMaterno}
                    onChange={handleAMChange}
                    required="true"
                    sx={{ backgroundColor: "rgba(217, 217, 217, 0.34)", border: "none" }}
                  ></CustomInput>
                </CustomBox>

                {/* Section with Sexo, Fecha Nacicmiento, No. Certificado & Fecha Carta */}
                {/* ***TYPE A ONLY*** */}
                {tipoSolicitudId === "A" && editIndex !== null ? (
                  <>
                    {/* Title row - TYPE A */}

                    {/* Sexo Title - TYPE A*/}
                    {sexoIdError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                              marginTop: "0.125rem",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox gridColumn="span 5">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Sexo
                      </CustomTypography>
                    </CustomBox>

                    {/* Fecha Nacimiento Title - TYPE A */}
                    {fechaNacimientoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                              marginTop: "0.125rem",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox textAlign="center" gridColumn="span 5">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Fecha de Nacimiento
                      </CustomTypography>
                    </CustomBox>

                    {/* No. Certificado Title - TYPE A */}
                    {numCertifiicadoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                              marginTop: "0.125rem",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}
                    <CustomBox gridColumn="span 5">
                      {" "}
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                      >
                        No. Certificado
                      </CustomTypography>
                    </CustomBox>

                    {/* Fecha Carta Title - TYPE A */}
                    {fechaCartaReconocimientoError2 === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                              marginTop: "0.125rem",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox gridColumn="span 5">
                      {" "}
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Fecha carta
                      </CustomTypography>
                    </CustomBox>

                    {/* Selection Row - TYPE A */}

                    {/* Sexo Select - TYPE A */}
                    <CustomBox gridColumn="span 6">
                      <FormControl fullWidth required="true">
                        <Select
                          labelId="sexoId"
                          id="sexoId"
                          value={sexoId}
                          onChange={handleSexoChange}
                          sx={{
                            height: "4.5vh",
                            backgroundColor: "rgba(217, 217, 217, 0.34)",
                            border: "none",
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDownOutlinedIcon
                              color="black"
                              sx={{ fontSize: "3rem", mr: 1 }}
                            />
                          )}
                        >
                          {sexValues.map((sex) => (
                            <MenuItem value={sex.id} key={sex.id} sx={{ fontWeight: "bold" }}>
                              {sex.sexo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CustomBox>

                    {/* Fecha Nacimiento Select - TYPE A */}
                    <CustomBox gridColumn="span 6">
                      <DatePicker
                        style={{ width: "100%", height: "100%" }}
                        placeholder="aaaa-mm-dd"
                        format={dateFormat}
                        value={fechaNacimiento ? dayjs(fechaNacimiento) : ""}
                        onChange={handleFechaNacimientoChange}
                        disabledDate={(current) => {
                          const maxDate =
                            relacionId === 1 || relacionId === 2
                              ? dayjs().subtract(18, "year")
                              : dayjs().subtract(0, "year");
                          const minDate = dayjs().subtract(100, "year");
                          return (
                            (maxDate.isValid() && current.isAfter(maxDate, "day")) ||
                            (minDate.isValid() && current.isBefore(minDate, "day"))
                          );
                        }}
                        required={true}
                        error={relacionId !== 2 && !fechaNacimiento}
                        helperText={
                          relacionId !== 2 && !fechaNacimiento ? "Este campo es requerido" : ""
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        popupStyle={{
                          top: "20%",
                          left: "50%",
                        }}
                      />
                    </CustomBox>

                    <CustomBox gridColumn="span 6">
                      <CustomInput
                        type="text"
                        fullWidth
                        onChange={handleNoCertificadoChange}
                        value={numCertifiicado}
                        required="true"
                        sx={{
                          backgroundColor: "rgba(217, 217, 217, 0.34)",
                          border: "none",
                        }}
                      ></CustomInput>
                    </CustomBox>

                    {/* Fecha Carta Select - TYPE A */}
                    <CustomBox gridColumn="span 6">
                      <DatePicker
                        style={{ width: "100%", height: "100%" }}
                        value={
                          //convert the date to a dayjs object
                          fechaCartaReconocimiento && editIndex !== null
                            ? dayjs(fechaCartaReconocimiento)
                            : ""
                        }
                        placeholder="aaaa-mm-dd"
                        format={dateFormat}
                        size="small"
                        required="true"
                        //limit the date selection up to 30 days in the past
                        disabledDate={(current) =>
                          current && current < dayjs().endOf("day").subtract(30, "days")
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onChange={handleFechaCartaChange}
                        popupStyle={{
                          top: "20%",
                          left: "50%",
                        }}
                      />
                    </CustomBox>
                  </>
                ) : (
                  <>
                    {/* Title Section - TYPE B,C,D */}
                    <CustomBox gridColumn="span 3"></CustomBox>

                    {/* Sexo Title - TYPE B,C,D */}
                    {sexoIdError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox gridColumn="span 5">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Sexo
                      </CustomTypography>
                    </CustomBox>

                    {/* Fecha Nacimiento Title - TYPE B,C,D */}
                    {fechaNacimientoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}

                    <CustomBox textAlign="center" gridColumn="span 5">
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          textAlign: "start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Fecha de Nacimiento
                      </CustomTypography>
                    </CustomBox>

                    {/* No. Certificado Title - TYPE B,C,D */}
                    {numCertifiicadoError === true ? (
                      <>
                        <CustomBox gridColumn="span 1">
                          <CircleIcon
                            sx={{
                              scale: ".5",
                              fontSize: "1.5rem",
                              color: "#EA5151",
                              float: "right",
                              marginTop: "0.125rem",
                            }}
                          />
                        </CustomBox>
                      </>
                    ) : (
                      <>
                        <CustomBox gridColumn="span 1"></CustomBox>
                      </>
                    )}
                    <CustomBox gridColumn="span 5">
                      {" "}
                      <CustomTypography
                        fontSize="0.9rem"
                        sx={{ fontWeight: "bold", color: "#000", whiteSpace: "nowrap" }}
                      >
                        No. Certificado
                      </CustomTypography>
                    </CustomBox>

                    <CustomBox gridColumn="span 3"></CustomBox>

                    {/* Selection Row - TYPE B,C,D */}
                    <CustomBox gridColumn="span 3"></CustomBox>
                    {/* Sexo Select - TYPE B,C,D */}
                    <CustomBox gridColumn="span 6">
                      <FormControl fullWidth required="true">
                        <Select
                          labelId="sexoId"
                          id="sexoId"
                          value={sexoId}
                          onChange={handleSexoChange}
                          sx={{
                            height: "4.5vh",
                            backgroundColor: "rgba(217, 217, 217, 0.34)",
                            border: "none",
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDownOutlinedIcon
                              color="black"
                              sx={{ fontSize: "3rem", mr: 1 }}
                            />
                          )}
                        >
                          {sexValues.map((sex) => (
                            <MenuItem value={sex.id} key={sex.id} sx={{ fontWeight: "bold" }}>
                              {sex.sexo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CustomBox>

                    {/* Fecha Nacimiento Select - TYPE B,C,D */}
                    <CustomBox gridColumn="span 6">
                      <DatePicker
                        style={{ width: "100%", height: "100%" }}
                        placeholder="aaaa-mm-dd"
                        format={dateFormat}
                        value={fechaNacimiento ? dayjs(fechaNacimiento) : ""}
                        onChange={handleFechaNacimientoChange}
                        disabledDate={(current) => {
                          const maxDate =
                            relacionId === 1 || relacionId === 2
                              ? dayjs().subtract(18, "year")
                              : dayjs().subtract(0, "year");
                          const minDate = dayjs().subtract(100, "year");
                          return (
                            (maxDate.isValid() && current.isAfter(maxDate, "day")) ||
                            (minDate.isValid() && current.isBefore(minDate, "day"))
                          );
                        }}
                        required={true}
                        error={relacionId !== 2 && !fechaNacimiento}
                        helperText={
                          relacionId !== 2 && !fechaNacimiento ? "Este campo es requerido" : ""
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                        popupStyle={{
                          top: "20%",
                          left: "50%",
                        }}
                      />
                    </CustomBox>

                    <CustomBox gridColumn="span 6">
                      <CustomInput
                        type="text"
                        fullWidth
                        onChange={handleNoCertificadoChange}
                        value={numCertifiicado}
                        required="true"
                        sx={{
                          backgroundColor: "rgba(217, 217, 217, 0.34)",
                          border: "none",
                        }}
                      ></CustomInput>
                    </CustomBox>

                    <CustomBox gridColumn="span 3"></CustomBox>
                  </>
                )}

                {/* Confirmar Button */}

                <CustomBox gridColumn="span 6"></CustomBox>
                <CustomBox gridColumn="span 6">
                  <CustomButton
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      width: "90%",
                      borderRadius: "2vh",
                      marginTop: "2rem",
                    }}
                    type="submit"
                    onClick={handleAnterior}
                    disabled={editIndex !== null}
                  >
                    <CustomTypography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                    >
                      Anterior
                    </CustomTypography>
                  </CustomButton>
                </CustomBox>
                <CustomBox gridColumn="span 6">
                  <CustomButton
                    variant="contained"
                    color="primary"
                    component="label"
                    size="small"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      width: "90%",
                      borderRadius: "2vh",
                      marginTop: "2rem",
                    }}
                    onClick={() => {
                      validateSecondStep(
                        relacionId,
                        nombre,
                        apellidoPaterno,
                        apellidoMaterno,
                        sexoId,
                        fechaNacimiento,
                        numCertifiicado,
                        fechaCartaReconocimiento
                      );
                    }}
                  >
                    <CustomTypography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#fff", fontSize: "0.8rem" }}
                    >
                      Aceptar
                    </CustomTypography>
                  </CustomButton>
                  <CustomBox gridColumn="span 6"> </CustomBox>
                </CustomBox>
              </CustomBox>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MassiveFormContentEdit;
