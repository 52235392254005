import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const ErrorContext = createContext();
const ErrorUpdateContext = createContext();

export function useError() {
  return useContext(ErrorContext);
}

export function useErrorUpdate() {
  return useContext(ErrorUpdateContext);
}

export function ErrorProvider({ children }) {
  const [error, setError] = useState([]);

  function updateError(newError) {
    setError(newError);
  }

  ErrorProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <ErrorContext.Provider value={error}>
      <ErrorUpdateContext.Provider value={updateError}>{children}</ErrorUpdateContext.Provider>
    </ErrorContext.Provider>
  );
}
