import { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";

export function useInactivityTimer(timeoutDuration = 600000, currentPath = "/") {
  // default is 10 minutes
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isInactive, setIsInactive] = useState(false);

  const resetTimer = useCallback(() => {
    setLastActivity(Date.now());
    setIsInactive(false);
  }, []);

  const handleInactivityAlert = async () => {
    let timerInterval;
    const result = await Swal.fire({
      title: "Tu sesión va a ser cerrada por inactividad.",
      html: "¿Deseas extender el tiempo? </br><b></b>",
      timer: 60000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: "Extender",
      cancelButtonText: "Cerrar Sesión",
      didOpen: () => {
        timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
            }
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    if (result.isConfirmed) {
      resetTimer();
    } else if (result.isDismissed) {
      setIsInactive(true);
    }
  };

  useEffect(() => {
    const checkInactivity = () => {
      if (
        currentPath !== "/" &&
        currentPath !== "/inicio" &&
        Date.now() - lastActivity > timeoutDuration
      ) {
        window.removeEventListener("mousemove", checkInactivity);
        handleInactivityAlert();
      }
    };

    window.addEventListener("mousemove", checkInactivity);
    window.addEventListener("click", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      window.removeEventListener("mousemove", checkInactivity);
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [lastActivity, resetTimer, timeoutDuration]);

  return [isInactive, resetTimer];
}
