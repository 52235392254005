import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const IndexContext = createContext();
const IndexUpdateContext = createContext();

export function useIndex() {
  return useContext(IndexContext);
}

export function useIndexUpdate() {
  return useContext(IndexUpdateContext);
}

export function IndexProvider({ children }) {
  const [edit, setEdit] = useState(null);

  function updateIndex(editIndex) {
    if (editIndex !== null && editIndex !== undefined) {
      setEdit(editIndex);
    }
  }

  IndexProvider.propTypes = {
    children: PropTypes.node,
  };

  return (
    <IndexContext.Provider value={edit}>
      <IndexUpdateContext.Provider value={updateIndex}>{children}</IndexUpdateContext.Provider>
    </IndexContext.Provider>
  );
}
