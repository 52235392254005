import React, { useEffect, useState } from "react";
import { auth } from "../../../context/AuthContext";
import axios from "axios";
import { Input, Table } from "antd";
import dayjs from "dayjs";
import "./styles.css";
import "dayjs/locale/es";
import { useUser } from "../../../context/UserContext";
import { set } from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";

const url = process.env.REACT_APP_ENTITY_ENDPOINTS_URL;

const Requests = () => {
  dayjs.locale("es");
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [backdropHeight, setBackdropHeight] = useState("90vh");
  const [boxHeight, setBoxHeight] = useState("90vh");
  const [isEmpty, setIsEmpty] = useState(false);
  const user = useUser();

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const backdropHeight = windowHeight - navbarHeight - footerHeight - 160;
      const boxHeight = windowHeight - navbarHeight - footerHeight - 175;
      setBackdropHeight(`${backdropHeight}px`);
      setBoxHeight(`${boxHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchSolicitudes = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 60000);
      });
      const result = await Promise.race([
        axios.get(url + "/clientes/" + user?.user?.cliente.id + "/solicitudes", {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }),
        timeout,
      ])
        .then((response) => {
          //remove any objects that don't have a numSolicitud that starts with OT-
          setData(
            response.data.filter((item) => item.numSolicitud && item.numSolicitud.startsWith("OT-"))
          );
          setIsLoading(false);
          setIsEmpty(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setIsEmpty(true);
        });
    };
    fetchSolicitudes();
  }, [user]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const filteredData = data.filter((record) => {
    const numSolicitud = record.numSolicitud
      ? `solicitud no. ${record.numSolicitud.toString()}`
      : "";
    const tipoSolicitud = record.tipoSolicitud.tipo.toLowerCase();
    const aseguradora = record.poliza.aseguradora.nombre.toLowerCase();
    const status = record.status.status.toLowerCase();
    const fechaModificacion = dayjs(record.createdAt).format("DD-MMMM-YYYY");
    const numBajas = `${record.asegurados.length} bajas`;
    const numAltas = `${record.asegurados.length} altas`;
    return (
      numSolicitud.includes(searchText) ||
      tipoSolicitud.includes(searchText.toLowerCase()) ||
      aseguradora.includes(searchText.toLowerCase()) ||
      status.includes(searchText.toLowerCase()) ||
      fechaModificacion.includes(searchText.toLowerCase()) ||
      numBajas.includes(searchText.toLowerCase()) ||
      numAltas.includes(searchText.toLowerCase())
    );
  });

  const handleViewDetails = (record) => {
    window.location = "resumen-orden?id=" + record.id;
  };

  const columns = [
    {
      title: "Número de Solicitud",
      dataIndex: "numSolicitud",
      key: "numSolicitud",
      align: "center",
      render: (numSolicitud) => `Solicitud no. ${numSolicitud}`,
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        if (a.numSolicitud && b.numSolicitud) {
          return a.numSolicitud.localeCompare(b.numSolicitud);
        } else if (a.numSolicitud) {
          return -1;
        } else if (b.numSolicitud) {
          return 1;
        } else {
          return 1; // Change this value to -1 if you want to sort null/empty values at the top
        }
      },
      sortDirections: ["descend", "ascend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar por número de solicitud"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "numSolicitud")}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              onClick={() => handleReset(clearFilters)}
              style={{ width: "40%", marginRight: 8 }}
            >
              Limpiar
            </button>
            <button
              type="submit"
              className="button button-primary"
              onClick={() => handleSearch(selectedKeys, confirm, "numSolicitud")}
              style={{ width: "40%" }}
            >
              Buscar
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Fecha de Modificación",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt) => dayjs(createdAt).format("DD-MMMM-YYYY"),
      //defaultSortOrder: "descend",
      sorter: (a, b) => {
        const dateA = a.createdAt;
        const dateB = b.createdAt;
        if (dateA && dateB) {
          return dateA.localeCompare(dateB);
        } else if (dateA) {
          return -1;
        } else if (dateB) {
          return 1;
        } else {
          return 1; // Change this value to -1 if you want to sort null/empty values at the top
        }
      },
      sortDirections: ["descend", "ascend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar por fecha de modificación"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "createdAt")}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              onClick={() => handleReset(clearFilters)}
              style={{ width: "40%", marginRight: 8 }}
            >
              Limpiar
            </button>
            <button
              type="submit"
              className="button button-primary"
              onClick={() => handleSearch(selectedKeys, confirm, "createdAt")}
              style={{ width: "40%" }}
            >
              Buscar
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Tipo de Solicitud",
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
      align: "center",
      render: (tipoSolicitud) => tipoSolicitud.tipo,
      sorter: (a, b) => a.tipoSolicitud.tipo.localeCompare(b.tipoSolicitud.tipo),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Número de Modificaciones",
      dataIndex: "asegurados",
      key: "asegurados",
      align: "center",
      render: (asegurados, record) => {
        const numBajas = asegurados.length;
        const numAltas = asegurados.length;
        const tipoSolicitud = record.tipoSolicitud;
        const isBaja = tipoSolicitud.id === 5 || tipoSolicitud.id === 4;
        return <span>{isBaja ? `${numBajas} Bajas` : `${numAltas} Altas`}</span>;
      },
      sorter: (a, b) => a.asegurados.length - b.asegurados.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Aseguradora",
      dataIndex: "poliza",
      key: "poliza",
      align: "center",
      render: (poliza) => poliza.aseguradora.nombre,
      sorter: (a, b) => a.poliza.aseguradora.nombre.localeCompare(b.poliza.aseguradora.nombre),
      sortDirections: ["descend", "ascend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar por aseguradora"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "poliza")}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              onClick={() => handleReset(clearFilters)}
              style={{ width: "40%", marginRight: 8 }}
            >
              Limpiar
            </button>
            <button
              type="submit"
              className="button button-primary"
              onClick={() => handleSearch(selectedKeys, confirm, "poliza")}
              style={{ width: "40%" }}
            >
              Buscar
            </button>
          </div>
        </div>
      ),
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let className = "";
        switch (status.status) {
          case "Pendiente":
            className = "status-in-progress";
            break;
          case "En Proceso":
            className = "status-approved";
            break;
          case "En revisión":
            className = "status-revision";
            break;
          case "Rechazo":
            className = "status-rejected";
            break;
          case "Cancelado":
            className = "status-rejected";
            break;
          case "Finalizado":
            className = "status-done";
            break;
          default:
            className = "status-default";
            break;
        }
        return <span className={className}>{status.status}</span>;
      },
      sorter: (a, b) => a.status.status.localeCompare(b.status.status),
      sortDirections: ["descend", "ascend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar por estatus"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "status")}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type="button"
              onClick={() => handleReset(clearFilters)}
              style={{ width: "40%", marginRight: 8 }}
            >
              Limpiar
            </button>
            <button
              type="submit"
              className="button button-primary"
              onClick={() => handleSearch(selectedKeys, confirm, "status")}
              style={{ width: "40%" }}
            >
              Buscar
            </button>
          </div>
        </div>
      ),
    },
  ];

  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    onChange: (page, pageSize) => {
      setPagination({ ...pagination, current: page, pageSize: pageSize });
    },
  });

  return (
    <>
      {isLoading ? (
        <>
          <CustomBox sx={{ height: boxHeight }} />
          <Backdrop
            sx={{
              height: backdropHeight,
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginLeft: "-20px",
                marginTop: "-20px",
              }}
            />
          </Backdrop>
        </>
      ) : (
        <>
          {isEmpty ? (
            <>
              <CustomBox sx={{ height: "20vh" }} />
              <CustomTypography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "regular",
                  color: "#039ECC",
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                No hay solicitudes para mostrar
              </CustomTypography>{" "}
            </>
          ) : (
            <>
              <Input.Search
                placeholder="Busca por fecha, nombre o status de la solicitud"
                allowClear
                size="large"
                onSearch={(value) => console.log(value)}
                style={{ width: "50%", marginBottom: "1rem" }}
                className="custom-input"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Table
                className="requests-table"
                columns={columns}
                dataSource={filteredData.slice().reverse()} // Reverse the order of the data
                rowKey={(record) => record.numSolicitud}
                onRow={(record) => {
                  return {
                    onClick: () => handleViewDetails(record),
                    style: { cursor: "pointer" },
                  };
                }}
                pagination={pagination}
              />{" "}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Requests;
