import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const DataContext = createContext();
const DataUpdateContext = createContext();

export function useData() {
  return useContext(DataContext);
}

export function useDataUpdate() {
  return useContext(DataUpdateContext);
}

export function DataProvider({ children }) {
  const [data, setData] = useState([]);

  function updateData(newData, isEdit, index) {
    if (newData.length === 0 || newData.length < data.length || isEdit) {
      let updatedData = [];
      if (isEdit) {
        updatedData = [...data];
        updatedData[index] = newData;
      } else {
        updatedData = newData;
      }
      setData(updatedData);
    } else {
      setData((prevState) => [...prevState, newData]);
    }
  }

  DataProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <DataContext.Provider value={data}>
      <DataUpdateContext.Provider value={updateData}>{children}</DataUpdateContext.Provider>
    </DataContext.Provider>
  );
}
