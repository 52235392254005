export default function MassiveDataProcess(input) {
  const processedData = input.reduce((acc, curr) => {
    switch (curr.tipoSolicitudId) {
      case "A": {
        const formattedData = {
          tipoSolicitudId: 1,
          motivoEndosoId: curr.motivoEndosoId,
          polizaId: curr.polizaId,
          asegurados: curr.asegurados,
          observaciones: curr.observaciones,
          fechaAlta: curr.fechaAlta,
        };
        if (curr.certificadoFile !== "") {
          formattedData.certificadoFile = curr.certificadoFile;
        }
        if (curr.cartaReconocimientoFile !== "") {
          formattedData.cartaReconocimientoFile = curr.cartaReconocimientoFile;
        }
        acc.push(formattedData);
        break;
      }

      case "B": {
        const formattedData = {
          tipoSolicitudId: 2,
          motivoEndosoId: curr.motivoEndosoId,
          polizaId: curr.polizaId,
          asegurados: curr.asegurados,
          observaciones: curr.observaciones,
          fechaAlta: curr.fechaAlta,
        };
        const existingObject = acc.find(
          (item) => item.tipoSolicitudId === curr.tipoSolicitudId && item.polizaId === curr.polizaId
        );
        if (existingObject) {
          existingObject.asegurados = existingObject.asegurados.concat(curr.asegurados);
        } else {
          acc.push(formattedData);
        }
        break;
      }

      case "C": {
        const formattedData = {
          tipoSolicitudId: 3,
          motivoEndosoId: curr.motivoEndosoId,
          polizaId: curr.polizaId,
          asegurados: curr.asegurados,
          observaciones: curr.observaciones,
          fechaAlta: curr.fechaAlta,
        };
        if (curr.actaNacimientoFile !== "") {
          formattedData.actaNacimientoFile = curr.actaNacimientoFile;
        }
        acc.push(formattedData);
        break;
      }
      case "D": {
        const formattedData = {
          tipoSolicitudId: 4,
          motivoEndosoId: curr.motivoEndosoId,
          polizaId: curr.polizaId,
          asegurados: curr.asegurados,
          observaciones: curr.observaciones,
          fechaBaja: curr.fechaBaja,
        };
        acc.push(formattedData);
        break;
      }
      default:
        break;
    }
    return acc;
  }, []);

  return processedData;
}
