import { useEffect, useState } from "react";
import { Table } from "antd";
import CustomBox from "components/CustomBox";
import CustomTypography from "components/CustomTypography";
import "./styles.css";
import { auth } from "../../../context/AuthContext";
import axios from "axios";
import { useUser } from "../../../context/UserContext";
import { Backdrop, CircularProgress } from "@mui/material";

const url = process.env.REACT_APP_NOTIFICATION_ENDPOINTS_URL;

const CustomTable = () => {
  const user = useUser();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [backdropHeight, setBackdropHeight] = useState("90vh");
  const [boxHeight, setBoxHeight] = useState("90vh");
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarElement = document.querySelector("#navbar");
      const footerElement = document.querySelector("#footer");
      const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
      const footerHeight = footerElement ? footerElement.offsetHeight : 0;
      const backdropHeight = windowHeight - navbarHeight - footerHeight - 160;
      const boxHeight = windowHeight - navbarHeight - footerHeight - 175;
      setBackdropHeight(`${backdropHeight}px`);
      setBoxHeight(`${boxHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let clienteId = user?.user?.cliente?.id;
    if (clienteId !== undefined) {
      const fetchNotificaciones = async () => {
        const userToken = await auth.currentUser.getIdToken(true);
        const timeout = new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error("Request timed out"));
          }, 60000);
        });
        const result = await Promise.race([
          axios.get(url + "/cliente/" + clienteId + "/notificaciones", {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }),
          timeout,
        ])
          .then((response) => {
            setData(response.data.filter((record) => !record.leido));
            setIsLoading(false);
            setIsEmpty(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setIsEmpty(true);
          });
      };
      fetchNotificaciones();
    }
  }, [user]);

  const handleRowClick = (record) => {
    if (selectedRowKeys.includes(record.id)) {
      // If the clicked row is already selected, do nothing
      return;
    }
    // Otherwise, add the clicked row to the selectedRowKeys array and read it
    setSelectedRowKeys((prevSelectedRowKeys) => [...prevSelectedRowKeys, record.id]);
    const readNotificacion = async () => {
      const userToken = await auth.currentUser.getIdToken(true);
      const result = await axios
        .put(
          url + "/notificaciones/" + record.id,
          {},
          {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((response) => {
          setSelectedRowKeys((prevSelectedRowKeys) => [...prevSelectedRowKeys, record.id]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    readNotificacion();
  };

  const rowClassName = (record, index) => {
    const isSelected = selectedRowKeys.includes(record.id);
    return isSelected ? "selected-row" : index % 2 === 0 ? "grey-row" : "white-row";
  };

  const columns = [
    {
      title: "Solicitud / Tipo / Fecha",
      dataIndex: "solicitud",
      key: "solicitud",
      render: (text, record) => (
        <CustomBox>
          <CustomTypography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: selectedRowKeys.includes(record.id) ? "#B0B7B9" : "#039ECC",
            }}
          >
            {record.solicitud.numSolicitud} / {record.fecha}
          </CustomTypography>
          <CustomTypography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              color: selectedRowKeys.includes(record.id) ? "#B0B7B9" : "#000",
            }}
          >
            {record.contenido}
          </CustomTypography>
        </CustomBox>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <>
          <CustomBox sx={{ height: boxHeight }} />
          <Backdrop
            sx={{
              height: backdropHeight,
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
          >
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                marginLeft: "-20px",
                marginTop: "-20px",
              }}
            />
          </Backdrop>
        </>
      ) : (
        <>
          {isEmpty ? (
            <>
              <CustomBox sx={{ height: "10vh" }} />
              <CustomTypography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "regular",
                  color: "#039ECC",
                  marginTop: "2rem",
                }}
              >
                No hay notificaciones nuevas para mostrar
              </CustomTypography>
            </>
          ) : (
            <></>
          )}
          <CustomBox width="90%" height="100%" p={2} mt={2} mb={24}>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              rowClassName={rowClassName}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
          </CustomBox>
        </>
      )}
    </>
  );
};

export default CustomTable;
