import React, { useContext, useEffect, useState } from "react";
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import PropTypes from "prop-types";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const auth = getAuth();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then((credential) => {
      if (!credential.user.emailVerified) {
        try {
          sendEmailVerification(credential.user);
          return "Verification_process";
        } catch (e) {
          return e;
        }
      }
      if (credential.user && !credential.user.multiFactor?.enrolledFactors.length) {
        // This user doesn't have MFA enabled, navigate them to MFA setup
        window.location = "/mfa-setup";
      }
      return credential;
    });
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
  };

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
