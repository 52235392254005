import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const MassiveDialogContext = createContext();
const MassiveDialogUpdateContext = createContext();

export function useMassiveDialog() {
  return useContext(MassiveDialogContext);
}

export function useMassiveDialogUpdate() {
  return useContext(MassiveDialogUpdateContext);
}

export function MassiveDialogProvider({ children }) {
  const [open, setOpen] = useState(false);

  function toggleMassiveDialog() {
    setOpen((prevState) => !prevState);
  }

  MassiveDialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <MassiveDialogContext.Provider value={open}>
      <MassiveDialogUpdateContext.Provider value={toggleMassiveDialog}>
        {children}
      </MassiveDialogUpdateContext.Provider>
    </MassiveDialogContext.Provider>
  );
}
